import React from 'react';

import { t } from 'i18next';

import { Stat, StatsContainer } from './styles';

interface InteractionCounterProps {
  likes: number;
  comments: number;
}

const InteractionCounter: React.FC<InteractionCounterProps> = ({
  likes,
  comments,
}) => (
  <StatsContainer>
    <Stat>
      {likes} {t('likes')}
    </Stat>
    <Stat>
      {comments} {t('comments')}
    </Stat>
  </StatsContainer>
);

export default InteractionCounter;
