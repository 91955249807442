import styled from 'styled-components';

export const BodyContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const ContentImageContainer = styled.div<{ isImageSmall: boolean }>`
  display: flex;
  flex-direction: ${({ isImageSmall }) => (isImageSmall ? 'row' : 'column')};
  align-items: ${({ isImageSmall }) =>
    isImageSmall ? 'center' : 'flex-start'};
`;

export const ImageWrapper = styled.div<{ isImageSmall: boolean }>`
  margin-left: ${({ isImageSmall }) => (isImageSmall ? '16px' : '0')};
  margin-top: ${({ isImageSmall }) => (isImageSmall ? '0' : '16px')};
  width: ${({ isImageSmall }) => (isImageSmall ? 'auto' : '100%')};
  height: auto;
`;
