import styled from 'styled-components';

import { Tag } from 'antd';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const TagsContainer = styled.div`
  display: flex;
`;

export const CustomTag = styled(Tag)`
  background-color: ${COLORS.colorBgTextHover};
  color: ${COLORS.Grey9};
  font-size: ${FONTS.SUB_TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  border: none;
  border-radius: 16px;
  padding: 1px 8px;
  margin-bottom: 10px;
`;
