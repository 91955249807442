import { getAppRole } from 'utils';

import LeftSidebar from 'components/shared/LeftSideBar';
import RightSidebar from 'components/shared/RightSideBar';
import { USER_ROLES } from 'constants/types';

import { OyoAdminDashboard } from './components/AdminDashboard/OyoAdminDashboard';
import { PmoDashboard } from './components/AdminDashboard/PmoDashboard';
import { CreateTopic } from './components/AdminDashboard/components/CreateTopic';
import { RegularDashboard } from './components/RegularDashboard';
import { Content, DashboardContainer } from './styles';

const ForumDashboard = () => {
  const role = getAppRole();

  const renderDashboard = (role: USER_ROLES) => {
    switch (role) {
      case USER_ROLES.ADMIN:
        return <OyoAdminDashboard />;
      case USER_ROLES.PMO_ADMIN:
        return <PmoDashboard />;
      default:
        return <RegularDashboard />;
    }
  };

  return (
    <DashboardContainer>
      <LeftSidebar />
      <Content>{renderDashboard(role)}</Content>
      <RightSidebar />
    </DashboardContainer>
  );
};

export default ForumDashboard;
