import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { t } from 'i18next';

import { PREVIEW_TEXT_LENGHT } from 'constants/global';

import { Content, ContentWrapper, StyledText, Title } from './styles';

interface TopicContentProps {
  title: string;
  content: string;
}

const TopicContent: React.FC<TopicContentProps> = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const isContentLong = content.length > PREVIEW_TEXT_LENGHT;
  const contentPreview =
    isExpanded || !isContentLong
      ? content
      : content.substring(0, PREVIEW_TEXT_LENGHT) + '...';

  return (
    <>
      <Title>{title}</Title>
      <ContentWrapper>
        <Content>{contentPreview}</Content>
        {isContentLong && (
          <StyledText onClick={toggleContent}>
            {isExpanded ? t('see_less') : t('see_more')}
          </StyledText>
        )}
      </ContentWrapper>
    </>
  );
};

export default TopicContent;
