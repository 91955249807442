import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const HorizontalBar = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.ColorSplit};
  margin: 16px 0;
`;

export const Container = styled.div`
  background-color: ${COLORS.BgContainer};
  width: 642px;
  height: 145px;
  border-radius: 5px;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 32px;
`;
