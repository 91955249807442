import styled from 'styled-components';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const Tab = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  text-transform: uppercase;

  font-size: ${FONTS.SUB_HEADER_FONT};
  line-height: 24px;

  padding-top: 16px;
  padding-bottom: 16px;
  color: ${(props) =>
    props.isActive ? COLORS.Green7 : COLORS.colorTextHeading};
  font-weight: ${(props) =>
    props.isActive ? FONTS_WEIGHT.TEXT_BOLD : FONTS_WEIGHT.TEXT_REGULAR};
  border-bottom: ${(props) =>
    props.isActive ? '2px solid green' : '2px solid transparent'};
  margin-right: 40px;
`;
