import styled from 'styled-components';

import { DownOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  width: 100%;
`;

export const DividerLine = styled.div`
  flex-grow: 1;
  width: 523px;
  height: 1px;
  background-color: ${COLORS.Grey10};
  margin-right: 30px;
  border: 1px;
`;

export const DividerContent = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  white-space: nowrap;
`;

export const DividerText = styled.span`
  text-transform: uppercase;
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  line-height: 20px;
  color: ${COLORS.Grey9};
`;

export const ArrowDownOutlined = styled(DownOutlined)`
  color: ${COLORS.Grey9};
  width: 14px;
  height: 14px;
  padding: 4px;
  margin-right: 12px;
`;
