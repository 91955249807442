import { styled } from 'styled-components';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  MailTwoTone,
} from '@ant-design/icons';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const SuccessIcon = styled(CheckCircleTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green7,
}))`
  font-size: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const CancelIcon = styled(CloseCircleTwoTone).attrs(() => ({
  twoToneColor: COLORS.ErrorBorder,
}))`
  font-size: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const WarningIcon = styled(ExclamationCircleTwoTone).attrs(() => ({
  twoToneColor: COLORS.WarningBase,
}))`
  font-size: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const InfoIcon = styled(ExclamationCircleTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green7,
}))`
  font-size: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const MailIcon = styled(MailTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green7,
}))`
  font-size: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const StyledModalBody = styled.div`
  padding: 32px;
`;

export const StyledModalTitle = styled.h5`
  text-align: center;
  font-size: ${FONTS.SUB_HEADER_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  line-height: 24px;
  margin-bottom: 8px;
`;

export const StyledModalSubtitle = styled.p`
  line-height: 22px;
  margin-bottom: 20px;
`;

export const StyledModalConfirmButton = styled.div<{ width: string }>`
  height: 36px;
  width: ${(props) => props.width};
`;
