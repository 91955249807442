import styled from 'styled-components';

import { DownOutlined, SearchOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const SidebarContainer = styled.div`
  width: 265px;
  height: 100%;
  padding-top: 24px;
  padding-left: 32px;
  box-sizing: border-box;
  background-color: ${COLORS.BgContainer};
  left: 0;
  position: fixed;
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  width: 217px;
  height: 32px;
  padding: 0px, 12px, 0px, 12px;
  background-color: ${COLORS.Grey8};
  border: 1px ${COLORS.Grey10};
  border-radius: 8px;
  margin-bottom: 18px;
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  color: ${COLORS.TextBase};
  &::placeholder {
    color: ${COLORS.colorTextSecondary};
    font-size: ${FONTS.BASE_TEXT_FONT};
    font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
    line-height: 22px;
  }
`;

export const IconSearchOutlined = styled(SearchOutlined)`
  color: ${COLORS.Grey9};
  width: 16px;
  height: 16px;
  padding-left: 12px;
`;

export const HorizontalBar = styled.div`
  width: 194px;
  border-bottom: 1px solid ${COLORS.ColorSplit};
  margin: 16px 0;
`;

export const MenuItem = styled.div<{
  active?: boolean;
  hasActiveSubItem?: boolean;
}>`
  display: flex;
  width: 232px;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? COLORS.Green1 : 'transparent'};
  border-radius: 6px 0px 0px 6px;
  color: ${(props) => {
    if (props.active && props.hasActiveSubItem) return COLORS.colorTextHeading;
    if (props.active) return COLORS.Green8;
    return COLORS.colorTextHeading;
  }};
  border-right: ${(props) =>
    props.active ? `2px solid ${COLORS.Green8}` : 'none'};
  .menu-item-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
`;

export const SubMenuItem = styled.div<{ active?: boolean }>`
  width: 198px;
  padding: 8px;
  margin-top: 5px;
  margin-left: 35px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? COLORS.Grey8 : 'transparent')};
  border-radius: 8px;
  color: ${(props) => (props.active ? COLORS.Green8 : COLORS.TextBase)};
`;

export const MenuIcon = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin: 4px;
  background-color: ${(props) =>
    props.active ? COLORS.BgContainer : COLORS.Grey12};
  border-radius: 6px;
`;

export const MenuImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const MenuText = styled.div`
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  line-height: 20px;
  cursor: pointer;
  margin-left: 8px;
`;

export const SubMenuText = styled.div<{ active?: boolean }>`
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${(props) =>
    props.active ? FONTS_WEIGHT.TEXT_BOLD : FONTS_WEIGHT.TEXT_REGULAR};
  line-height: 20px;
  cursor: pointer;
`;

export const MenuSubText = styled.div`
  font-size: ${FONTS.SUB_TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  line-height: 15px;
  padding-left: 8px;
  color: ${COLORS.colorTextSecondary};
`;

export const ArrowDownOutlined = styled(DownOutlined)`
  color: ${COLORS.Grey11};
  width: 12px;
  height: 12px;
  margin-right: 17px;
`;
