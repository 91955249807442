import React from 'react';

import { LikeOutlined } from '@ant-design/icons';

import { t } from 'i18next';

import { ButtonContainer, ButtonIcon, ButtonText } from './styles';

const LikeButton: React.FC = () => (
  <ButtonContainer>
    <ButtonIcon>
      <LikeOutlined />
    </ButtonIcon>
    <ButtonText>{t('like')}</ButtonText>
  </ButtonContainer>
);

export default LikeButton;
