import React from 'react';

import { NewtTopicTab } from './components/NewTopicTab';
import { PostOptionsTab } from './components/PostOptionsTab';
import { Container, HorizontalBar } from './styles';

interface CreateTopicProps {
  refetchPinned: () => void;
  refetchUnpinned: () => void;
}

export const CreateTopic: React.FC<CreateTopicProps> = ({
  refetchPinned,
  refetchUnpinned,
}) => (
  <Container>
    <NewtTopicTab
      refetchPinned={refetchPinned}
      refetchUnpinned={refetchUnpinned}
    />
    <HorizontalBar />
    <PostOptionsTab
      refetchPinned={refetchPinned}
      refetchUnpinned={refetchUnpinned}
    />
  </Container>
);
