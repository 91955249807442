import { getAppRole, getBaseRoute } from 'utils';

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Field, FieldProps, FormikProvider, useFormik } from 'formik';

import { UserAuth } from 'components/auth/authSlice';
import AuthWrapper from 'components/shared/AuthWrapper';
import { BtnPrimary } from 'components/shared/Button';
import ContactUs from 'components/shared/ContactUs';
import { InputGroup } from 'components/shared/InputGroup';
import { LinkPrimary } from 'components/shared/Link';
import { MarginTop32px } from 'components/shared/Margin';
import TermsAndConditions from 'components/shared/TermsAndConditions';
import { Header } from 'components/shared/Text';
import AppRoutes from 'constants/routes';
import { USER_ROLES } from 'constants/types';
import { useLoginMutation } from 'services/auth';
import { useGetPaymentsQuery } from 'services/paymentManagment';

import { useAppLanguage, useAppSelector } from 'utils/hooks';
import { getSignInSchema } from 'utils/validation/schemas';

import {
  StyledForgotPassword,
  StyledForm,
  StyledInput,
  StyledPasswordInput,
} from './styles';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const signInSchema = getSignInSchema(t);
  const [hasFormToBeValidated, setHasFormToBeValidated] = useState(false);
  const [isApiErrorVisible, setIsApiErrorVisible] = useState(true);
  const [skipPaymentFetch, setSkipPaymentFetch] = useState(true);
  const {
    data: paymentsData,
    isLoading: isLoadingPayments,
    isSuccess: isSuccessPayments,
  } = useGetPaymentsQuery({}, { skip: skipPaymentFetch });
  const { onLogin } = useAppLanguage();
  const [login, { isSuccess }] = useLoginMutation();

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: signInSchema,
    validateOnChange: true,
    onSubmit: async (values: UserAuth) => {
      login(values);
      if (isSuccess) {
        formik.setSubmitting(false);
        onLogin(values.username);
      }
    },
  });

  const handleLogin = async () => {
    setHasFormToBeValidated(true);
    setIsApiErrorVisible(false);
    formik.setSubmitting(true);
    const isValid = await formik.validateForm();
    if (isValid) {
      formik.handleSubmit();
    } else {
      formik.setSubmitting(false);
    }
  };

  const passwordErrorMessage = useMemo(() => {
    let errorMessage = '';
    if (hasFormToBeValidated) {
      if (formik.errors.password) {
        errorMessage = formik.errors.password;
      } else if (isApiErrorVisible) {
        errorMessage = t('incorrect_credentials');
      }
    }
    return errorMessage;
  }, [formik.errors, hasFormToBeValidated, isApiErrorVisible, t]);

  // redirect user directly to add payment page (prevent visiting dashboard page)
  useEffect(() => {
    if (isSuccess && !isLoadingPayments && isSuccessPayments) {
      const userRole = getAppRole();
      const hasPayments = !!paymentsData?.paymentMethods?.length;
      const isProducer = userRole === USER_ROLES.PRODUCER;

      if (!isProducer && !hasPayments) {
        navigate(AppRoutes.AddPayment);
      } else {
        navigate(getBaseRoute());
      }
    }
  }, [isLoadingPayments, isSuccess, paymentsData, isSuccessPayments]);

  // fetch payment method after sign in for consumer. others redirects to base route
  useEffect(() => {
    if (isSuccess) {
      const userRole = getAppRole();
      if (userRole === USER_ROLES.CONSUMER) {
        setSkipPaymentFetch(false);
      } else {
        navigate(getBaseRoute());
      }
    }
  }, [isSuccess]);

  return (
    <AuthWrapper>
      <FormikProvider value={formik}>
        <StyledForm>
          <Header>{t('login')}</Header>
          <MarginTop32px />
          <InputGroup
            label={formik.values.username ? t('email').toString() : ''}
            error={hasFormToBeValidated ? formik.errors.username : ''}
          >
            <Field name="username">
              {({ field, meta }: FieldProps) => (
                <StyledInput
                  {...field}
                  type="text"
                  placeholder={t('email').toString()}
                  onFocus={() => setIsApiErrorVisible(false)}
                  status={hasFormToBeValidated && !!meta.error ? 'error' : ''}
                />
              )}
            </Field>
          </InputGroup>
          <InputGroup
            label={formik.values.password ? t('password').toString() : ''}
            error={passwordErrorMessage}
          >
            <Field name="password">
              {({ field, meta }: FieldProps) => (
                <StyledPasswordInput
                  {...field}
                  type="text"
                  placeholder={t('password').toString()}
                  onFocus={() => setIsApiErrorVisible(false)}
                  status={hasFormToBeValidated && !!meta.error ? 'error' : ''}
                />
              )}
            </Field>
          </InputGroup>
          <StyledForgotPassword>
            <LinkPrimary to={AppRoutes.ForgotPassword}>
              {t('forgot_password')}
            </LinkPrimary>
          </StyledForgotPassword>

          <BtnPrimary
            isDisabled={
              isLoadingPayments || (hasFormToBeValidated && !formik.isValid)
            }
            additionalStyles={{ width: '324px' }}
            onClick={handleLogin}
          >
            {isLoadingPayments ? t('logging') : t('login')}
          </BtnPrimary>
          <MarginTop32px />
          <TermsAndConditions />
          <ContactUs />
        </StyledForm>
      </FormikProvider>
    </AuthWrapper>
  );
};

export default Login;
