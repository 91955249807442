import { getAppRole, getAuthUser, timeAgo } from 'utils';

import React from 'react';
import { useTranslation } from 'react-i18next';

import UserIcon from 'components/shared/UserIcon';
import { ROLES } from 'constants/global';
import { Topic } from 'constants/types';
import { useGetCommunityQuery } from 'services/communityManagement';
import { useGetTopicCategoryByIdQuery } from 'services/topicCategoryManagement';

import {
  CommunityName,
  StyledRole,
  StyledUserName,
} from '../../../../CreateTopic/components/CreateTopicModal/styles';
import DropdownMenu from '../../shared/DropdownMenu';
import {
  CustomTag,
  HeaderContainer,
  PostDate,
  StyledUserDetails,
} from './styles';

interface TopicHeaderProps {
  topic: Topic;
  refetchPinned: () => void;
  refetchUnpinned: () => void;
}

const TopicHeader: React.FC<TopicHeaderProps> = ({
  topic,
  refetchPinned,
  refetchUnpinned,
}) => {
  const { t } = useTranslation();
  const { data: community } = useGetCommunityQuery();
  const { data: category } = useGetTopicCategoryByIdQuery(topic.category);

  const roleLabel = (() => {
    switch (topic.author.role) {
      case ROLES.PMO:
        return t('pmo');
      case ROLES.PRODUCER:
        return t('producer');
      case ROLES.OYO_ADMIN:
        return t('oyo_admin');
      case ROLES.CONSUMER:
        return t('consumer');
    }
  })();

  return (
    <HeaderContainer>
      <StyledUserDetails>
        <UserIcon
          firstName={topic.author.firstName}
          lastName={topic.author.lastName}
          userRole={topic.author.role}
        />
        <StyledUserName>
          <StyledRole>{roleLabel}</StyledRole>
          <span> • </span>
          <CommunityName>
            {topic.author.role === ROLES.PMO
              ? community?.communityName
              : t('oyo_communitites')}
          </CommunityName>
          <PostDate>{timeAgo(topic.createdAt)}</PostDate>
        </StyledUserName>
      </StyledUserDetails>
      <div style={{ display: 'flex', paddingRight: '16px' }}>
        <CustomTag>{t(category?.title || '')}</CustomTag>
        <DropdownMenu
          topic={topic}
          refetchPinned={refetchPinned}
          refetchUnpinned={refetchUnpinned}
        />
      </div>
    </HeaderContainer>
  );
};

export default TopicHeader;
