import styled from 'styled-components';

import { Button, Checkbox, Input, Modal, Select } from 'antd';

import {
  CloseCircleFilled,
  CloseOutlined,
  DownOutlined,
} from '@ant-design/icons';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const ErrorIcon = styled(CloseCircleFilled)`
  font-size: ${FONTS.TEXT_FONT};
  color: ${COLORS.ErrorBase};
  margin-right: 4px;
`;
export const ErrorText = styled.span`
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  line-height: 20px;
  letter-spacing: 0;
  color: ${COLORS.ErrorBase};
`;

export const ErrorContainer = styled.div`
  min-height: 20px;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    padding: 20px;
  }

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .ant-modal-title {
    font-size: ${FONTS.SUB_HEADER_FONT};
    font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
    line-height: 24px;
    color: ${COLORS.TextBase};
  }

  .ant-modal-body {
    padding-top: 10px;
  }

  min-height: 480px;
  max-height: 632px;
  }
`;

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledUserDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledUserName = styled.div`
  font-size: ${FONTS.TEXT_FONT};
  line-height: 20px;
`;

export const StyledRole = styled.span`
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  color: ${COLORS.ActiveUserText};
`;

export const CommunityName = styled.span`
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  color: ${COLORS.colorTextDropDown};
`;

export const Bullet = styled.span`
  margin: 0 6px;
  color: ${COLORS.ActiveUserText};
`;

export const PinPostWrapper = styled.div`
  display: flex;
  align-items: top;
`;

export const PinPostLabel = styled.div`
  font-size: ${FONTS.BASE_TEXT_FONT};
  color: ${COLORS.Grey8};
`;

export const StyledPinButton = styled(Button)<{ pinned: boolean }>`
  width: auto;
  height: 24px;
  border: 1px solid ${({ pinned }) => (pinned ? COLORS.Green6 : COLORS.Grey7)};
  border-radius: 4px;
  background-color: ${({ pinned }) =>
    pinned ? COLORS.Green1 : COLORS.BgContainer};
  color: ${({ pinned }) => (pinned ? COLORS.Green8 : COLORS.TextBase)};
  box-shadow: 0px 2px 0px 0px ${COLORS.colorText};
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  align-items: center;
  .anticon {
    font-size: ${FONTS.SUB_HEADER_FONT};
    color: ${({ pinned }) => (pinned ? COLORS.Green8 : COLORS.Green6)};
  }
`;

export const StyledFormItem = styled.div`
  display: flex;
  align-items: center;
  .ant-select-selector {
    border-radius: 8px;
    font-size: ${FONTS.BASE_TEXT_FONT};
    width: 100%;
  }
`;

export const StyledLabel = styled.label`
  margin-left: 8px;
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  line-height: 20px;
  color: ${COLORS.ActiveUserText};
  white-space: nowrap;
`;

export const StyledFormButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  button {
    border-radius: 5px;
    padding: 6px 20px;
    width: 100%;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-top: 8px;
`;
export const StyledPostCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;

  span {
    font-size: ${FONTS.TEXT_FONT};
    color: ${COLORS.colorTextDropDown};
  }
`;

export const StyledDropdownIcon = styled(DownOutlined)`
  margin-left: 8px;
  font-size: ${FONTS.SUB_TEXT_FONT};
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 24px 12px 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  }
`;

export const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
  width: 146px;
  height: 32px;
  &:hover {
    background-color: ${COLORS.Grey8};
  }
`;

export const StyledMenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  background-color: ${COLORS.Grey8};
  border-radius: 8px;
  img {
    width: 20px;
    height: 20px;
  }
`;

export const StyledMenuText = styled.div`
  font-size: ${FONTS.BASE_TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  color: ${COLORS.Grey6};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
`;

export const StyledTextArea = styled(Input.TextArea).attrs(() => ({
  autoSize: { minRows: 3 },
}))`
  border: none;
  box-shadow: none;
  height: auto;
  resize: none;
  color: ${COLORS.Grey6};
  line-height: 22px;
  overflow: hidden;

  &::placeholder {
    font-size: ${FONTS.BASE_TEXT_FONT};
    font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
    line-height: 22px;
    color: ${COLORS.Grey6};
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

export const ScrollableContentContainer = styled.div`
  max-height: 383px;
  overflow-y: auto;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  margin: 16px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.BgContainerDisabled};
    border-radius: 9px;
    border: none;
  }

  scrollbar-width: thin;
  scrollbar-color: ${COLORS.BgContainerDisabled} transparent;

  -ms-overflow-style: -ms-autohiding-scrollbar;
`;

export const ImagePreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  video {
    width: 100%;
    height: auto; /* Ensure the media maintains its aspect ratio */
    object-fit: cover;
    border-radius: 10px;
  }
`;

export const RemoveImageButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${COLORS.Grey8};
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCloseOutlined = styled(CloseOutlined)`
  width: 8.66px;
  height: 8.91px;
  color: ${COLORS.TextDescription};
`;

export const StyledSelectWrapper = styled.div`
  min-width: 115px;
  height: 22px;
  border-radius: 6px;
  background-color: ${COLORS.Grey13};
  border: 1px solid ${COLORS.Grey14};

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    display: flex;
    align-items: center;
  }

  .ant-select-selection-item {
    text-transform: uppercase;
    font-size: ${FONTS.SUB_TEXT_FONT};
    font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
    line-height: 20px;
    color: ${COLORS.colorTextDropDown};
  }

  .ant-select-arrow {
    width: 14px;
    height: 14px;
    color: ${COLORS.colorTextDropDown};
  }

  .ant-select-dropdown {
    background-color: ${COLORS.Grey13} !important;
    border: 1px solid ${COLORS.Grey14} !important;
    border-radius: 6px !important;
    width: auto !important;
  }

  .ant-select-item {
    text-transform: uppercase;
    font-size: ${FONTS.SUB_TEXT_FONT} !important;
    font-weight: ${FONTS_WEIGHT.TEXT_BOLD} !important;
    line-height: 20px !important;
    color: ${COLORS.colorTextDropDown} !important;
  }
`;

export const StyledCategoryLabel = styled.span`
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  line-height: 20px;
  color: ${COLORS.colorTextDropDown};
  white-space: nowrap;
`;
