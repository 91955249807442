import Divider from 'components/shared/Divider';
import { Topic } from 'constants/types';
import { useGetCommunityQuery } from 'services/communityManagement';
import {
  useGetPinnedTopicsByCommunityQuery,
  useGetUnpinnedTopicsByCommunityQuery,
} from 'services/topicManagement';

import { CreateTopic } from '../components/CreateTopic';
import { TopicCard } from '../components/DisplayTopic/TopicCard';
import TopicCarousel from '../components/DisplayTopic/TopicCarousel';

export const PmoDashboard = () => {
  const { data: community } = useGetCommunityQuery();

  const { data: pinnedtopics = [], refetch: refetchPinned } =
    useGetPinnedTopicsByCommunityQuery(community?.id!, {
      skip: !community?.id,
    });

  const { data: unpinnedtopics = [], refetch: refetchUnpinned } =
    useGetUnpinnedTopicsByCommunityQuery(community?.id!, {
      skip: !community?.id,
    });

  return (
    <>
      <CreateTopic
        refetchPinned={refetchPinned}
        refetchUnpinned={refetchUnpinned}
      />
      <TopicCarousel
        topics={pinnedtopics}
        refetchPinned={refetchPinned}
        refetchUnpinned={refetchUnpinned}
      />
      <Divider />
      <TopicCard
        topics={unpinnedtopics}
        refetchPinned={refetchPinned}
        refetchUnpinned={refetchUnpinned}
      />
    </>
  );
};
