import styled from 'styled-components';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 56px;
  margin-left: 56px;
`;

export const Stat = styled.div`
  font-size: ${FONTS.BASE_TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  line-height: 22px;
  color: ${COLORS.Grey6};

  &:not(:first-child) {
    margin-left: 40px;
  }
`;
