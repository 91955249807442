import { getAppRole, getAuthUser } from 'utils';

import React, { useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Field, Form, Formik } from 'formik';

import { Button, Select, Spin } from 'antd';

import { PushpinOutlined } from '@ant-design/icons';

import DropZone from 'components/shared/DropZone';
import UserIcon from 'components/shared/UserIcon';
import { ROLES } from 'constants/global';
import {
  useGetCommunitiesQuery,
  useGetCommunityQuery,
} from 'services/communityManagement';
import { useGetAllTopicsCategoriesQuery } from 'services/topicCategoryManagement';
import { useAddTopicMutation } from 'services/topicManagement';

import { createTopicValidationSchema } from 'utils/validation/schemas';

import GroupIcon from '../PostOptionsTab/icons/Group.svg';
import NewsIcon from '../PostOptionsTab/icons/News.svg';
import ResourcesIcon from '../PostOptionsTab/icons/Resources.svg';
import {
  Bullet,
  CommunityName,
  Container,
  ErrorContainer,
  ErrorIcon,
  ErrorText,
  ImagePreviewContainer,
  PinPostWrapper,
  RemoveImageButton,
  ScrollableContentContainer,
  StyledCategoryLabel,
  StyledCloseOutlined,
  StyledForm,
  StyledFormButtons,
  StyledFormItem,
  StyledHeader,
  StyledLabel,
  StyledMenu,
  StyledMenuIcon,
  StyledMenuText,
  StyledModal,
  StyledPinButton,
  StyledPostCategory,
  StyledRole,
  StyledSelectWrapper,
  StyledTextArea,
  StyledUserDetails,
  StyledUserName,
} from './styles';

const { Option } = Select;

export interface ICreateTopicFormValues {
  description: string;
  communities: string[];
  category: string;
  pinned: boolean;
  files: File[];
}

const initialFormDefaultValues: ICreateTopicFormValues = {
  description: '',
  category: '',
  communities: [],
  pinned: false,
  files: [],
};

interface CreateTopicModalProps {
  isVisible: boolean;
  onClose: () => void;
  refetchPinned: () => void;
  refetchUnpinned: () => void;
}

const CreateTopicModal: React.FC<CreateTopicModalProps> = ({
  isVisible,
  onClose,
  refetchPinned,
  refetchUnpinned,
}) => {
  const { t } = useTranslation();
  const [addTopic] = useAddTopicMutation();
  const [UploadedFiles, setUploadedFiles] = useState<
    Array<{ file: FileWithPath; preview: string }>
  >([]);
  const [submitting, setSubmitting] = useState(false);
  const { data: communities = [] } = useGetCommunitiesQuery();
  const { data: categories = [] } = useGetAllTopicsCategoriesQuery();
  const { data: community } = useGetCommunityQuery();
  const userRole = getAppRole();
  const [isPinned, setIsPinned] = useState(false);
  const [isAllCommunitiesSelected, setIsAllCommunitiesSelected] =
    useState(true);
  const [initialFormValues, setInitialFormValues] =
    useState<ICreateTopicFormValues>(initialFormDefaultValues);

  useEffect(() => {
    if (categories.length > 0 && communities.length > 0) {
      setInitialFormValues({
        description: '',
        category: categories[0]._id,
        communities: communities.map((community) => community.id),
        pinned: false,
        files: [],
      });
    }
  }, [categories, communities]);

  const handleTopicCategoryChange = (value: string, setFieldValue: any) => {
    setFieldValue('category', value);
  };

  const handleCommunityChange = (
    value: string[] | string,
    setFieldValue: any,
  ) => {
    if (value.includes('all_communities')) {
      setFieldValue(
        'communities',
        communities.map((community) => community.id),
      );
      setIsAllCommunitiesSelected(true);
    } else {
      setFieldValue('communities', value);
      setIsAllCommunitiesSelected(false);
    }
  };

  const togglePinPost = () => {
    setIsPinned((prev) => !prev);
  };

  const handleSubmit = async (values: ICreateTopicFormValues, actions: any) => {
    try {
      setSubmitting(true);

      const formData = new FormData();
      formData.append('description', values.description);
      formData.append('category', values.category);
      formData.append('pinned', String(isPinned));

      let communitiesArray;

      if (userRole === ROLES.PMO && community?.id) {
        communitiesArray = [community.id];
      } else {
        communitiesArray = Array.isArray(values.communities)
          ? values.communities
          : [values.communities];
      }

      communitiesArray.forEach((community) =>
        formData.append('communities', community),
      );

      values.files.forEach((file) => formData.append('mediaFiles', file));

      await addTopic(formData).unwrap();

      setUploadedFiles([]);
      actions.resetForm();
      refetchPinned();
      refetchUnpinned();
      onClose();
    } catch (error) {
      console.error('Failed to add topic:', error);
    } finally {
      setSubmitting(false);
      actions.setSubmitting(false);
    }
  };

  const handleDrop = (acceptedFiles: FileWithPath[]) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeImage = (fileToRemove: FileWithPath) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter(({ file }) => file !== fileToRemove),
    );
  };

  const handleRemoveFileClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    file: FileWithPath,
  ) => {
    e.preventDefault();
    removeImage(file);
  };

  const handleModalClose = () => {
    setUploadedFiles([]);
    setInitialFormValues({
      description: '',
      category: categories[0]?._id || '',
      communities: communities.map((community) => community.id) || [],
      pinned: false,
      files: [],
    });
    onClose();
  };

  const handleOnFocus = (field: string, setErrors: any) => {
    setErrors((prevErrors: any) => ({ ...prevErrors, [field]: undefined }));
  };

  const handleOnSave = (validateForm: any, handleSubmit: any) => {
    validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        handleSubmit();
      }
    });
  };

  return (
    <StyledModal
      title={t('create_new_post')}
      open={isVisible}
      onCancel={handleModalClose}
      footer={null}
    >
      <Spin
        spinning={submitting}
        size="large"
        tip={t('loading')}
        style={{ color: '#166534' }}
      >
        <Formik
          validateOnChange={false}
          validateOnBlur={true}
          initialValues={initialFormValues}
          validationSchema={createTopicValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isValid,
            isSubmitting,
            values,
            errors,
            validateForm,
            setFieldValue,
            setErrors,
            handleSubmit,
          }) => (
            <Form>
              <StyledForm>
                <StyledHeader>
                  <StyledUserDetails>
                    <UserIcon
                      firstName={getAuthUser().firstName}
                      lastName={getAuthUser().lastName}
                      userRole={getAppRole()}
                    />
                    <div>
                      <StyledUserName>
                        <StyledRole>
                          {userRole === ROLES.PMO ? 'PMO' : 'OYO'}
                        </StyledRole>
                        <Bullet>•</Bullet>
                        <CommunityName>
                          {userRole === ROLES.PMO
                            ? community?.communityName
                            : 'OYO Communities'}
                        </CommunityName>
                      </StyledUserName>

                      <StyledPostCategory>
                        <StyledCategoryLabel>
                          {t('post_category')}
                        </StyledCategoryLabel>
                        <StyledSelectWrapper>
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            onChange={(value) =>
                              handleTopicCategoryChange(value, setFieldValue)
                            }
                            value={values.category}
                            status={errors.category ? 'error' : undefined}
                            onFocus={() => handleOnFocus('category', setErrors)}
                          >
                            {categories.map((category) => (
                              <Option key={category._id} value={category._id}>
                                {t(category.title)}
                              </Option>
                            ))}
                          </Select>
                          <ErrorContainer>
                            {errors.category && (
                              <>
                                <ErrorIcon />
                                <ErrorText>{errors.category}</ErrorText>
                              </>
                            )}
                          </ErrorContainer>
                        </StyledSelectWrapper>
                      </StyledPostCategory>
                    </div>
                  </StyledUserDetails>
                  <PinPostWrapper>
                    <StyledPinButton
                      pinned={isPinned}
                      icon={<PushpinOutlined />}
                      onClick={togglePinPost}
                    >
                      {t(isPinned ? 'pinned_post' : 'pin_post')}
                    </StyledPinButton>
                  </PinPostWrapper>
                </StyledHeader>

                {userRole !== ROLES.PMO && (
                  <StyledFormItem>
                    <StyledLabel>{t('share_post_in')}</StyledLabel>
                    <Select
                      style={{ width: '386px', margin: '0 12px' }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value) =>
                        handleCommunityChange(value, setFieldValue)
                      }
                      value={
                        isAllCommunitiesSelected
                          ? 'all_communities'
                          : values.communities
                      }
                      status={errors.communities ? 'error' : undefined}
                      onFocus={() => handleOnFocus('communities', setErrors)}
                    >
                      <Option key="all_communities" value="all_communities">
                        {t('all_communities')}
                      </Option>
                      {communities.map((community) => (
                        <Option key={community.id} value={community.id}>
                          {community.communityName}
                        </Option>
                      ))}
                    </Select>
                  </StyledFormItem>
                )}

                <ScrollableContentContainer>
                  <StyledFormItem>
                    <Field
                      name="description"
                      as={StyledTextArea}
                      placeholder={t('what_about')}
                      status={errors.description ? 'error' : undefined}
                      onFocus={() => handleOnFocus('description', setErrors)}
                    />
                    <ErrorContainer>
                      {errors.description && (
                        <>
                          <ErrorIcon />
                          <ErrorText>{errors.description}</ErrorText>
                        </>
                      )}
                    </ErrorContainer>
                  </StyledFormItem>

                  {UploadedFiles.length === 0 && (
                    <StyledFormItem>
                      <DropZone
                        onDrop={(acceptedFiles: FileWithPath[]) => {
                          handleDrop(acceptedFiles);
                          setFieldValue('files', [
                            ...UploadedFiles.map((f) => f.file),
                            ...acceptedFiles,
                          ]);
                        }}
                      />
                    </StyledFormItem>
                  )}

                  {UploadedFiles.map(({ file, preview }, index) => (
                    <ImagePreviewContainer key={index}>
                      {file.type.startsWith('image/') ? (
                        <img src={preview} alt={`Preview ${index + 1}`} />
                      ) : (
                        <video
                          controls
                          src={preview}
                          style={{ maxWidth: '100%', borderRadius: '10px' }}
                        />
                      )}
                      <RemoveImageButton
                        onClick={(e) => handleRemoveFileClick(e, file)}
                      >
                        <StyledCloseOutlined />
                      </RemoveImageButton>
                    </ImagePreviewContainer>
                  ))}
                </ScrollableContentContainer>

                <Container>
                  <StyledMenu>
                    <StyledMenuIcon>
                      <img src={NewsIcon} alt={t('article')} />
                    </StyledMenuIcon>
                    <StyledMenuText>{t('article')}</StyledMenuText>
                  </StyledMenu>
                  <StyledMenu>
                    <StyledMenuIcon>
                      <img src={GroupIcon} alt={t('vote')} />
                    </StyledMenuIcon>
                    <StyledMenuText>{t('vote')}</StyledMenuText>
                  </StyledMenu>
                  <StyledMenu>
                    <StyledMenuIcon>
                      <img src={ResourcesIcon} alt={t('meeting')} />
                    </StyledMenuIcon>
                    <StyledMenuText>{t('meeting')}</StyledMenuText>
                  </StyledMenu>
                </Container>

                <StyledFormButtons>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => handleOnSave(validateForm, handleSubmit)}
                    loading={isSubmitting}
                    disabled={!isValid || isSubmitting}
                  >
                    {t('post')}
                  </Button>
                </StyledFormButtons>
              </StyledForm>
            </Form>
          )}
        </Formik>
      </Spin>
    </StyledModal>
  );
};

export default CreateTopicModal;
