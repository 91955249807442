import Slider from 'react-slick';

import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const CustomCarousel = styled(Slider)`
  margin-bottom: 31px;
  .slick-slide {
    transition: opacity 0.8s ease-in-out;
    opacity: 0;
  }

  .slick-active {
    opacity: 1;
  }
  .slick-dots {
    li {
      button {
        width: 8px;
        height: 8px;
        padding: 0;

        &::before {
          content: '';
          display: block;
          border-radius: 50px;
          width: 8px;
          height: 8px;
          background: ${COLORS.colorTextSecondary};
        }
      }
      &.slick-active button::before {
        opacity: 1;
      }
    }
  }
`;
