import React, { useState } from 'react';

import Divider from 'components/shared/Divider';
import { Topic } from 'constants/types';
import {
  useGetPinnedTopicsByCommunityQuery,
  useGetUnpinnedTopicsByCommunityQuery,
} from 'services/topicManagement';

import CommunityTabs from '../components/CommunityTabs';
import { CreateTopic } from '../components/CreateTopic';
import { TopicCard } from '../components/DisplayTopic/TopicCard';
import TopicCarousel from '../components/DisplayTopic/TopicCarousel';

export const OyoAdminDashboard: React.FC = () => {
  const [selectedCommunityId, setSelectedCommunityId] = useState<string>('');

  const { data: pinnedTopics = [], refetch: refetchPinned } =
    useGetPinnedTopicsByCommunityQuery(selectedCommunityId, {
      skip: !selectedCommunityId,
    });

  const { data: unpinnedTopics = [], refetch: refetchUnpinned } =
    useGetUnpinnedTopicsByCommunityQuery(selectedCommunityId, {
      skip: !selectedCommunityId,
    });

  return (
    <>
      <CreateTopic
        refetchPinned={refetchPinned}
        refetchUnpinned={refetchUnpinned}
      />

      <CommunityTabs onSelectCommunity={setSelectedCommunityId} />
      {pinnedTopics.length > 0 && (
        <TopicCarousel
          topics={pinnedTopics}
          refetchPinned={refetchPinned}
          refetchUnpinned={refetchUnpinned}
        />
      )}
      <Divider />
      <TopicCard
        topics={unpinnedTopics}
        refetchPinned={refetchPinned}
        refetchUnpinned={refetchUnpinned}
      />
    </>
  );
};
