import { createLogger } from 'redux-logger';

import { configureStore } from '@reduxjs/toolkit';

import { authApi } from 'services/auth';
import { communityManagement } from 'services/communityManagement';
import { paymentApi } from 'services/paymentManagment';
import { topicCategoryManagement } from 'services/topicCategoryManagement';
import { topicManagement } from 'services/topicManagement';
import { userManagement } from 'services/userManagement';

const logger = createLogger();

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userManagement.reducerPath]: userManagement.reducer,
    [topicManagement.reducerPath]: topicManagement.reducer,
    [communityManagement.reducerPath]: communityManagement.reducer,
    [topicCategoryManagement.reducerPath]: topicCategoryManagement.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userManagement.middleware,
      topicManagement.middleware,
      communityManagement.middleware,
      topicCategoryManagement.middleware,
      paymentApi.middleware,
      logger,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
