import React from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';

import { PictureFilled } from '@ant-design/icons';

import { DropZoneContainer, Icon, SubText, Text } from './styles';

interface DropZoneProps {
  onDrop: (acceptedFiles: FileWithPath[]) => void;
}

const DropZone: React.FC<DropZoneProps> = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropZoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      <Icon>
        <PictureFilled />
      </Icon>
      <Text>Add Photo / Video</Text>
      <SubText>or Drag and Drop</SubText>
    </DropZoneContainer>
  );
};

export default DropZone;
