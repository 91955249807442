import { getOnlineConsumerLegalInitials } from 'utils';

import React from 'react';

import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';

import { ThunderboltIcon, UserIcons } from './styles';

interface UserIconProps {
  userRole: string;
  firstName: string;
  lastName: string;
}

const getIconContent = (
  userRole: string,
  firstName: string,
  lastName: string,
) => {
  switch (userRole) {
    case USER_ROLES.ADMIN:
      return 'OYO';
    case USER_ROLES.PMO_ADMIN:
      return 'PMO';
    case USER_ROLES.PRODUCER:
      return <ThunderboltIcon />;
    case USER_ROLES.CONSUMER:
      return getOnlineConsumerLegalInitials(firstName, lastName);
    default:
      return '';
  }
};

const getIconColor = (userRole: string) => {
  switch (userRole) {
    case USER_ROLES.ADMIN:
      return COLORS.Orange5;
    case USER_ROLES.PMO_ADMIN:
      return COLORS.geekBlue5;
    case USER_ROLES.PRODUCER:
      return COLORS.Yellow5;
    case USER_ROLES.CONSUMER:
      return COLORS.Green5;
    default:
      return '';
  }
};

const getIconBorderColor = (userRole: string) => {
  switch (userRole) {
    case USER_ROLES.ADMIN:
      return COLORS.Orange8;
    case USER_ROLES.PMO_ADMIN:
      return COLORS.Blue4;
    case USER_ROLES.PRODUCER:
      return COLORS.Yellow6;
    case USER_ROLES.CONSUMER:
      return COLORS.Green6;
    default:
      return '';
  }
};

const UserIcon: React.FC<UserIconProps> = ({
  userRole,
  firstName,
  lastName,
}) => {
  return (
    <UserIcons
      color={getIconColor(userRole)}
      borderColor={getIconBorderColor(userRole)}
    >
      {getIconContent(userRole, firstName, lastName)}
    </UserIcons>
  );
};

export default UserIcon;
