import AppRoutes from './routes';

export const CONTACT_US_EMAIL = 'support@oyo.ai';

export const SUPPORTED_LANGUAGES = {
  English: 'en',
  Français: 'fr',
};

export enum MIME_TYPE {
  JSON = 'application/json',
}

export const PASWORD_MIN_LENGTH = 8;

export enum BarType {
  CREATE_TOPIC_SECTION = 'CREATE_TOPIC_SECTION',
  VIEW_TOPIC_SECTION = 'VIEW_TOPIC_SECTION',
}

export enum REQUEST_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum ROLES {
  OYO_ADMIN = 'OYO_Admin',
  PMO = 'PMO',
  CONSUMER = 'Consumer',
  PRODUCER = 'Producer',
  NO_ROLE = '',
}

export enum COUNTRY {
  FRANCE = 'fr',
}

export const DATE_FORMAT = 'DD/MM/YYYY';

export const PREVIEW_TEXT_LENGHT = 455;

export const SCREEN_CONTENT_SIZE = '1380px';

export const DATE_FORMAT_TYPE = 'YYYY-MM-DD';

export const MONTH_FORMAT_TYPE = 'YYYY-MM';

export const API_URLS = {
  OAUTH_TOKEN: '/oauth/token',
  TOPICS: '/topics',
  PAYMENT_METHOD: '/payment-method',

  CATEGORY: 'category',
  TOPICS_CATEGORY: '/topics_category',
  RECENT_PINNED: 'recent_pinned',
  GET_COMMUNITY: '/community',
  UPDATE_PASSWORD: '/users/update_password',
  REQUEST_CHANGE_PASSWORD: '/users/request_change_password',
  MEMBERS: '/users',
  MEMBER: 'users/user',
  GET_USER_BY_EMAIL: '/users/user_by_email',
  PINNED_COMMUNITY: 'pinned_community',
  UNPINNED_COMMUNITY: 'unpinned_community',
  GET_COMMUNITIES: 'community/all',
  AUTH_CHECK: '/users/check_auth',
  VALIDATE_TOKEN: (token: string) => `/users/validate_change_password/${token}`,
  USER_LANGUAGE: '/users/lang',
  RESEND_INVITE: 'resend_invite',
};

export const COMMUNITIES_NAV_LINKS_ROLE_OYO_ADMIN = [
  { title: 'communities', path: AppRoutes.Communities },
  { title: 'members', path: AppRoutes.Members },
  { title: 'pricing', path: AppRoutes.Pricing },
  { title: 'forum', path: AppRoutes.Forum },
];

export const COMMUNITIES_NAV_LINKS_ROLE_PMO = [
  { title: 'my_community', path: AppRoutes.MyCommunity },
  { title: 'members', path: AppRoutes.Members },
  { title: 'reports', path: AppRoutes.Reports },
  { title: 'pricing', path: AppRoutes.Pricing },
  { title: 'forum', path: AppRoutes.Forum },
];

export const COMMUNITIES_NAV_LINKS_ROLE_CONSUMER = [
  { title: 'dashboard', path: AppRoutes.Dashboard },
  { title: 'energy_data', path: AppRoutes.EnergyData, isDisabled: false },
  { title: 'financial_data', path: AppRoutes.FinancialData },
  { title: 'forum', path: AppRoutes.Forum },
];

export const COMMUNITIES_NAV_LINKS_ROLE_PRODUCER = [
  { title: 'dashboard', path: AppRoutes.Dashboard },
  { title: 'energy_data', path: AppRoutes.EnergyData, isDisabled: false },
  { title: 'financial_data', path: AppRoutes.FinancialData, isDisabled: false },
  { title: 'prices', path: AppRoutes.Pricing },
  { title: 'forum', path: AppRoutes.Forum },
];

export const USER_MENU_ITEMS_ROLE_CONSUMER = [
  { label: 'profile', key: '0', route: AppRoutes.Profile },
  { label: 'settings', key: '1', route: AppRoutes.Profile, tab: 'Settings' },
  { type: 'divider', key: '2' },
  { label: 'sign_out', key: '3' },
];

export const USER_MENU_ITEMS_ROLE_PRODUCER = [
  { label: 'profile', key: '0', route: AppRoutes.Profile },
  { label: 'settings', key: '1', route: AppRoutes.Profile, tab: 'Settings' },
  { type: 'divider', key: '2' },
  { label: 'sign_out', key: '3' },
];

export const USER_MENU_ITEMS_ROLE_OYO_ADMIN = [
  { label: 'profile', key: '0', route: AppRoutes.Profile },
  { type: 'divider', key: '1' },
  { label: 'sign_out', key: '2' },
];

export const USER_MENU_ITEMS_ROLE_PMO = [
  { label: 'profile', key: '0', route: AppRoutes.Profile },
  { type: 'divider', key: '1' },
  { label: 'sign_out', key: '2' },
];

export enum MenuItemType {
  MyFeed = 'myFeed',
  MyCommunity = 'myCommunity',
  CollectiveSelfConsumptions = 'collectiveSelfConsumptions',
  Messages = 'messages',
  AppFeedbacks = 'appFeedbacks',
  NewestAndRecent = 'newestAndRecent',
  PopularOfDay = 'popularOfDay',
}
