import { useState } from 'react';

import { t } from 'i18next';

import { MODAL_TYPE } from 'constants/types';

import CreateTopicModal from '../CreateTopicModal';
import GroupIcon from './icons/Group.svg';
import NewsIcon from './icons/News.svg';
import ResourcesIcon from './icons/Resources.svg';
import { Container, Menu, MenuIcon, MenuText } from './styles';

interface PostOptionsTabProps {
  refetchPinned: () => void;
  refetchUnpinned: () => void;
}

export const PostOptionsTab: React.FC<PostOptionsTabProps> = ({
  refetchPinned,
  refetchUnpinned,
}) => {
  const [isTopicModalVisible, setIsTopicModalVisible] = useState(false);
  const [isPollModalVisible, setIsPollModalVisible] = useState(false);

  const showModal = (modalType: MODAL_TYPE) => {
    switch (modalType) {
      case MODAL_TYPE.TOPIC:
        setIsTopicModalVisible(true);
        break;
      case MODAL_TYPE.POLL:
        setIsPollModalVisible(true);
        break;
    }
  };

  const closeModal = (modalType: MODAL_TYPE) => {
    switch (modalType) {
      case MODAL_TYPE.TOPIC:
        setIsTopicModalVisible(false);
        break;
      case MODAL_TYPE.POLL:
        setIsPollModalVisible(false);
        break;
    }
  };

  return (
    <Container>
      <Menu onClick={() => showModal(MODAL_TYPE.TOPIC)}>
        <MenuIcon>
          <img src={NewsIcon} alt={t('article')} />
        </MenuIcon>
        <MenuText>{t('article')}</MenuText>
      </Menu>
      <Menu onClick={() => showModal(MODAL_TYPE.POLL)}>
        <MenuIcon>
          <img src={GroupIcon} alt={t('vote')} />
        </MenuIcon>
        <MenuText>{t('vote')}</MenuText>
      </Menu>
      <Menu>
        <MenuIcon>
          <img src={ResourcesIcon} alt={t('meeting')} />
        </MenuIcon>
        <MenuText>{t('meeting')}</MenuText>
      </Menu>
      <CreateTopicModal
        refetchPinned={refetchPinned}
        refetchUnpinned={refetchUnpinned}
        isVisible={isTopicModalVisible}
        onClose={() => closeModal(MODAL_TYPE.TOPIC)}
      />
    </Container>
  );
};
