import { getAppRole, getAuthUser } from 'utils';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import UserIcon from 'components/shared/UserIcon';

import CreateTopicModal from '../CreateTopicModal';
import { Container, SearchInput } from './styles';

interface NewTopicTabProps {
  refetchPinned: () => void;
  refetchUnpinned: () => void;
}

export const NewtTopicTab: React.FC<NewTopicTabProps> = ({
  refetchPinned,
  refetchUnpinned,
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Container>
      <UserIcon
        firstName={getAuthUser().firstName}
        lastName={getAuthUser().lastName}
        userRole={getAppRole()}
      />
      <SearchInput
        type="text"
        placeholder={t('create_new_post')}
        readOnly
        onClick={showModal}
      />
      <CreateTopicModal
        isVisible={isModalVisible}
        onClose={closeModal}
        refetchPinned={refetchPinned}
        refetchUnpinned={refetchUnpinned}
      />
    </Container>
  );
};
