import { ReactNode } from 'react';

import { styled } from 'styled-components';

import { Button } from 'antd';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

const ButtonSecondary = styled(Button)`
  margin-top: 20px;
  width: 100%;
  font-size: ${FONTS.BASE_TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  padding: 0;
  color: ${(props) => (props.color ? props.color : COLORS.Green8)};

  &:hover {
    color: ${COLORS.TextBase} !important;
  }

  &:disabled {
    color: ${COLORS.Grey3};
  }

  &:hover:disabled {
    color: ${COLORS.Grey3} !important;
  }
`;
interface IBtnSecondary {
  isDisabled?: boolean;
  onClick?: () => void;
  color?: string;
  htmlType?: string;
  children: ReactNode;
}
export const BtnSecondary = ({
  isDisabled,
  color,
  onClick,
  children,
}: IBtnSecondary) => (
  <ButtonSecondary
    type="link"
    size="large"
    disabled={isDisabled}
    onClick={onClick}
    color={color}
  >
    {children}
  </ButtonSecondary>
);
