import React from 'react';

import { CustomTag, TagsContainer } from './styles';

interface TagsProps {
  tags: string[];
}

const Tags: React.FC<TagsProps> = ({ tags }) => (
  <TagsContainer>
    {tags.map((tag, index) => (
      <CustomTag key={index}>{tag}</CustomTag>
    ))}
  </TagsContainer>
);

export default Tags;
