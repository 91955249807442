import styled from 'styled-components';

import { ThunderboltTwoTone } from '@ant-design/icons';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const SidebarContainer = styled.div`
  width: 326px;
  height: 100%;
  padding: 20px 12px 16px 12px;
  margin-top: 10px;
  right: 0;
  position: fixed;
`;
export const Container = styled.div`
  width: 179px;
  margin: 20px;
`;

export const SidebarHeader = styled.div`
  font-size: ${FONTS.SUB_HEADER_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  line-height: 24px;
  margin-bottom: 20px;
  color: ${COLORS.TextBase};
`;

export const UserName = styled.div`
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  line-height: 20px;
  color: ${COLORS.ActiveUserText};
`;

export const UserRole = styled.div`
  font-size: ${FONTS.SUB_TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  line-height: 15px;
  color: ${COLORS.TextSecondary};
`;

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const StatusIcon = styled.div<{ borderColor: string }>`
  display: flex;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  color: ${COLORS.BgContainer};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 21px;
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  font-size: ${FONTS.TEXT_FONT};
  line-height: 20px;
  margin-right: 10px;
`;

export const ThunderboltIcon = styled(ThunderboltTwoTone).attrs(() => ({
  twoToneColor: COLORS.Yellow2,
}))`
  font-size: ${FONTS.SUB_HEADER_FONT};
`;
