import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const Image = styled.img`
  border-radius: 6px;
  border: 1px solid ${COLORS.Grey14};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Container = styled.div`
  height: 300px;
  overflow: hidden;
  border-radius: 6px;
  margin-bottom: 10px;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
