import { ROLES } from './global';

export enum USER_ROLES {
  PMO_ADMIN = 'PMO',
  ADMIN = 'OYO_Admin',
  CONSUMER = 'Consumer',
  PRODUCER = 'Producer',
}

export enum MODAL_TYPE {
  TOPIC = 'topic',
  POLL = 'poll',
  MEET = 'meet',
}

export enum USER_STATUSES {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  CONFLICT = 'Conflict',
  DISABLED = 'Disabled',
  PROBATION = 'Probation',
}

export enum MEDIA_TYPE {
  IMAGE = 'image',
  VIDEO = 'video',
}

export interface ILocation {
  type: 'Point';
  coordinates: [number, number];
  _id?: number;
}

export interface IDBAddress {
  city: string;
  country: string;
  postalCode: number;
  street: string;
  building: string;
  location: ILocation;
}

export interface IUser {
  position?: string;
  email?: string;
  contactNumber?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
}

export interface IDBUser extends IUser {
  _id: string;
  auth0Id?: string;
  role: ROLES;
  status?: USER_STATUSES;
  address: IDBAddress;
}

export interface Community {
  id: string;
  communityName: string;
  location: string;
  coordinates: number[];
  pvPower: string;
  pmoFirstName: string;
  pmoLastName: string;
  pmoMiddleName: string;
  pmoId: string;
  pmoEmail: string;
  selfConsumedEnergy: number;
  gridEnergy: number;
  perimeter: number;
  pmoOrgName?: string;
}

export type CustomSelectItem = {
  label: string | null;
  key: string | number;
};

export interface Topic {
  _id: string;
  title: string;
  description: string;
  pinned: boolean;
  communities: string[];
  author: IDBUser;
  media?: Media[];
  category: string;
  createdAt: string;
}

export interface Media {
  _id: string;
  mediaType: MEDIA_TYPE;
  originalname: string;
  mimetype: string;
  size: number;
  filename: string;
  filePath: string;
}

export interface TopicCategory {
  _id: string;
  title: string;
}
