import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItemType } from 'constants/global';
import { useGetAllTopicsCategoriesQuery } from 'services/topicCategoryManagement';

import AppFeedbacksIcon from './icons/appfeedbacks.svg';
import MessagesIcon from './icons/messeges.svg';
import MyCommunityIcon from './icons/mycommunity.svg';
import NewIcon from './icons/new.svg';
import PopularOfDayIcon from './icons/popularofday.svg';
import {
  ArrowDownOutlined,
  HorizontalBar,
  IconSearchOutlined,
  MenuIcon,
  MenuItem,
  MenuSubText,
  MenuText,
  SearchBar,
  SearchInput,
  SidebarContainer,
  SubMenuItem,
  SubMenuText,
} from './styles';

const LeftSidebar = () => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState<MenuItemType | null>(null);
  const [activeSubItem, setActiveSubItem] = useState<string | null>(null);
  const { data: categories = [] } = useGetAllTopicsCategoriesQuery();

  const handleMenuItemClick = (item: MenuItemType) => {
    setActiveItem((prevActiveItem) => (prevActiveItem === item ? null : item));
    setActiveSubItem(null);
  };

  const handleSubMenuItemClick = (subItem: string) => {
    setActiveSubItem(subItem);
  };

  const isFeedOpen = activeItem === MenuItemType.MyFeed;

  return (
    <SidebarContainer>
      <SearchBar>
        <IconSearchOutlined />
        <SearchInput type="text" placeholder={t('search')} />
      </SearchBar>
      <MenuItem
        active={activeItem === MenuItemType.MyFeed}
        hasActiveSubItem={!!activeSubItem}
        onClick={() => handleMenuItemClick(MenuItemType.MyFeed)}
      >
        <div className="menu-item-container">
          <MenuIcon
            active={activeItem === MenuItemType.MyFeed || !!activeSubItem}
          >
            <img src={MyCommunityIcon} alt={t('my_feed')} />
          </MenuIcon>
          <MenuText>{t('my_feed')}</MenuText>
        </div>
        <div>
          <ArrowDownOutlined />
        </div>
      </MenuItem>
      {isFeedOpen && (
        <>
          {categories.map((category) => (
            <SubMenuItem
              key={category._id}
              active={activeSubItem === category.title}
              onClick={() => handleSubMenuItemClick(category.title)}
            >
              <SubMenuText active={activeSubItem === category.title}>
                {t(category.title)}
              </SubMenuText>
            </SubMenuItem>
          ))}
        </>
      )}
      <MenuItem
        active={activeItem === MenuItemType.Messages}
        onClick={() => handleMenuItemClick(MenuItemType.Messages)}
      >
        <MenuIcon active={activeItem === MenuItemType.Messages}>
          <img src={MessagesIcon} alt={t('messages')} />
        </MenuIcon>
        <MenuText>{t('messages')}</MenuText>
      </MenuItem>
      <MenuItem
        active={activeItem === MenuItemType.AppFeedbacks}
        onClick={() => handleMenuItemClick(MenuItemType.AppFeedbacks)}
      >
        <MenuIcon active={activeItem === MenuItemType.AppFeedbacks}>
          <img src={AppFeedbacksIcon} alt={t('app_feedbacks')} />
        </MenuIcon>
        <MenuText>{t('app_feedbacks')}</MenuText>
      </MenuItem>
      <HorizontalBar />
      <MenuItem
        active={activeItem === MenuItemType.NewestAndRecent}
        onClick={() => handleMenuItemClick(MenuItemType.NewestAndRecent)}
      >
        <MenuIcon active={activeItem === MenuItemType.NewestAndRecent}>
          <img src={NewIcon} alt={t('newest_and_recent')} />
        </MenuIcon>
        <div>
          <MenuText>{t('newest_and_recent')}</MenuText>
          <MenuSubText>{t('latest_posts')}</MenuSubText>
        </div>
      </MenuItem>
      <MenuItem
        active={activeItem === MenuItemType.PopularOfDay}
        onClick={() => handleMenuItemClick(MenuItemType.PopularOfDay)}
      >
        <MenuIcon active={activeItem === MenuItemType.PopularOfDay}>
          <img src={PopularOfDayIcon} alt={t('popular_of_the_day')} />
        </MenuIcon>
        <div>
          <MenuText>{t('popular_of_the_day')}</MenuText>
          <MenuSubText>{t('featured')}</MenuSubText>
        </div>
      </MenuItem>
    </SidebarContainer>
  );
};

export default LeftSidebar;
