import React, { useEffect, useState } from 'react';

import { useGetCommunitiesQuery } from 'services/communityManagement';

import CommunityTab from './components/CommunityTab';
import { TabsContainer } from './styles';

interface CommunityTabsProps {
  onSelectCommunity: (communityId: string) => void;
}

const CommunityTabs: React.FC<CommunityTabsProps> = ({ onSelectCommunity }) => {
  const { data: communities = [] } = useGetCommunitiesQuery();
  const [activeTab, setActiveTab] = useState<string>('');

  useEffect(() => {
    if (communities.length > 0) {
      const defaultCommunityId = communities[0].id;
      setActiveTab(defaultCommunityId);
      onSelectCommunity(defaultCommunityId);
    }
  }, [communities, onSelectCommunity]);

  const handleTabClick = (communityId: string) => {
    setActiveTab(communityId);
    onSelectCommunity(communityId);
  };

  return (
    <TabsContainer>
      {communities.map((community) => (
        <CommunityTab
          key={community.id}
          name={community.communityName}
          isActive={activeTab === community.id}
          onClick={() => handleTabClick(community.id)}
        />
      ))}
    </TabsContainer>
  );
};

export default CommunityTabs;
