import dayjs from 'dayjs';

import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Badge, Dropdown } from 'antd';

import type { TFunction } from 'i18next';

import {
  BellIcon,
  ErrorIcon,
  InfoIcon,
  StyledAvatar,
  StyledNotificationAction,
  StyledNotificationCol,
  StyledNotificationContainer,
  StyledNotificationDate,
  StyledNotificationRow,
  StyledNotificationTitle,
  StyledNotificationType,
  StyledNotificationsBody,
  StyledNotificationsContainer,
  StyledNotificationsEmpty,
  StyledNotificationsHeader,
  StyledNotificationsWrapper,
  SuccessIcon,
  UnreadIcon,
  WarningIcon,
  dropdownOverlayStyle,
} from './styles';

enum NOTIFICATION_TYPE {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}

interface IDUMMY_NOTIFICATION {
  id: string;
  title: string;
  text: string;
  type: NOTIFICATION_TYPE;
  actionLink: string | null;
  actionTitle: string | null;
  date: string;
  isRead: boolean;
}

const dummyData = [
  {
    id: '0',
    key: '0',
    title: 'some title 1',
    text: 'Interactively monetize corporate alignments and fully tested niche markets.',
    type: NOTIFICATION_TYPE.warning,
    actionLink: null,
    actionTitle: null,
    date: '2024-05-24T09:51:01.902Z',
    isRead: false,
  },
  {
    id: '1',
    key: '1',
    title: 'some title 2',
    text: 'Interactively monetize corporate alignments and fully tested niche markets.',
    type: NOTIFICATION_TYPE.info,
    actionLink: '/profile',
    actionTitle: 'go to profile',
    date: '2024-06-04T09:51:01.902Z',
    isRead: false,
  },
  {
    id: '2',
    key: '2',
    title: 'some title 3',
    text: 'Interactively monetize corporate alignments and fully tested niche markets.',
    type: NOTIFICATION_TYPE.success,
    actionLink: '/profile',
    actionTitle: 'go to profile',
    date: '2024-06-01T09:51:01.902Z',
    isRead: true,
  },
  {
    id: '3',
    key: '3',
    title: 'some title 4',
    text: 'Interactively monetize corporate alignments and fully tested niche markets.',
    type: NOTIFICATION_TYPE.error,
    actionLink: null,
    actionTitle: null,
    date: '2024-05-04T09:51:01.902Z',
    isRead: true,
  },
];

const getTypeIcon = (type: NOTIFICATION_TYPE) => {
  switch (type) {
    case 'success':
      return <SuccessIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'info':
      return <InfoIcon />;
  }
};

const formatDate = (date: string, t: TFunction) => {
  const currentDate = dayjs();
  const dateObj = dayjs(date);
  const weekDiff = currentDate.diff(dateObj, 'week');
  if (weekDiff) return t('week_ago', { count: weekDiff });
  const dayDiff = currentDate.diff(dateObj, 'day');
  if (dayDiff) return t('day_ago', { count: dayDiff });
  const hourDiff = currentDate.diff(dateObj, 'hour');
  if (hourDiff) return t('hour_ago', { count: hourDiff });
};

const MAX_NOTIFICATION_COUNT = 9;

const Notifications = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const count = useMemo(
    () => dummyData.filter(({ isRead }) => isRead).length,
    [],
  );

  const goTo = useCallback(
    (url: string) => {
      setIsMenuOpen(false);
      navigate(url);
    },
    [navigate],
  );

  const renderNotification = useCallback(
    (notification: IDUMMY_NOTIFICATION) => {
      const { id, title, text, type, actionLink, actionTitle, date, isRead } =
        notification;
      return (
        <StyledNotificationContainer isRead={isRead} key={id}>
          <StyledNotificationCol>
            <StyledNotificationType>{getTypeIcon(type)}</StyledNotificationType>
          </StyledNotificationCol>
          <StyledNotificationCol>
            <StyledNotificationRow>
              <StyledNotificationTitle>{title}</StyledNotificationTitle>
            </StyledNotificationRow>
            <StyledNotificationRow>{text}</StyledNotificationRow>
            <StyledNotificationRow>
              <StyledNotificationDate>
                {formatDate(date, t)}
              </StyledNotificationDate>
              {actionLink && actionTitle && (
                <StyledNotificationAction
                  size="small"
                  onClick={() => goTo(actionLink)}
                >
                  {actionTitle}
                </StyledNotificationAction>
              )}
            </StyledNotificationRow>
          </StyledNotificationCol>
          <StyledNotificationCol>
            {!isRead ? <UnreadIcon /> : <div style={{ width: '14px' }} />}
          </StyledNotificationCol>
        </StyledNotificationContainer>
      );
    },
    [],
  );

  const renderNotifications = useCallback(
    ({ props }: { props: { items: IDUMMY_NOTIFICATION[] } }) => {
      const { items } = props;

      return (
        <StyledNotificationsContainer>
          <StyledNotificationsHeader>
            {t('notifications')}
          </StyledNotificationsHeader>
          <StyledNotificationsBody>
            {items.length ? (
              items.map((item: IDUMMY_NOTIFICATION) => renderNotification(item))
            ) : (
              <StyledNotificationsEmpty>
                {t('no_notifications_yet')}
              </StyledNotificationsEmpty>
            )}
          </StyledNotificationsBody>
        </StyledNotificationsContainer>
      );
    },
    [],
  );

  const toggleMenu = useCallback(() => setIsMenuOpen((val) => !val), []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !(event.target as HTMLElement).closest('.dropdown-classss') &&
        !(event.target as HTMLElement).closest('.ant-dropdown')
      ) {
        setIsMenuOpen(false);
      }
    };
    const handleScrollOutside = () => setIsMenuOpen(false);
    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScrollOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScrollOutside);
    };
  }, []);

  return (
    <StyledNotificationsWrapper>
      <Dropdown
        className="dropdown-classss"
        trigger={['click']}
        open={isMenuOpen}
        placement="bottomLeft"
        overlayStyle={dropdownOverlayStyle as CSSProperties}
      >
        <Badge count={count} overflowCount={MAX_NOTIFICATION_COUNT}>
          <StyledAvatar shape="square" icon={<BellIcon />} />
        </Badge>
      </Dropdown>
    </StyledNotificationsWrapper>
  );
};

export default Notifications;
