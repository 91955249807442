import { jwtDecode } from 'jwt-decode';

import {
  COMMUNITIES_NAV_LINKS_ROLE_CONSUMER,
  COMMUNITIES_NAV_LINKS_ROLE_OYO_ADMIN,
  COMMUNITIES_NAV_LINKS_ROLE_PMO,
  COMMUNITIES_NAV_LINKS_ROLE_PRODUCER,
  ROLES,
  USER_MENU_ITEMS_ROLE_CONSUMER,
  USER_MENU_ITEMS_ROLE_OYO_ADMIN,
  USER_MENU_ITEMS_ROLE_PMO,
  USER_MENU_ITEMS_ROLE_PRODUCER,
} from 'constants/global';
import AppRoutes from 'constants/routes';
import { USER_ROLES } from 'constants/types';

import { LOCAL_STORAGE_KEYS } from './local-storage';

const decodeToken = (token: string) => jwtDecode(token);

export const isValidRole = (role: USER_ROLES) => {
  const token: any = localStorage.getItem(LOCAL_STORAGE_KEYS.IdToken);
  if (!token) return false;

  const decodedToken: any = decodeToken(token);

  return decodedToken?.oyo_roles.includes(role);
};

export const getAppRole = () => {
  const token: any = localStorage.getItem(LOCAL_STORAGE_KEYS.IdToken);
  if (!token) return ROLES.NO_ROLE;

  const decodedToken: any = decodeToken(token);

  return decodedToken?.oyo_roles[0];
};

export const getAuthUser = () => {
  const token: any = localStorage.getItem(LOCAL_STORAGE_KEYS.IdToken);
  if (!token) return null;

  const user: any = decodeToken(token);

  return user;
};

export const getConsumerLegalInitials = () => {
  const token: any = localStorage.getItem(LOCAL_STORAGE_KEYS.IdToken);
  if (!token) return null;

  const { given_name, family_name }: any = decodeToken(token);

  return `${given_name.charAt(0)}${family_name.charAt(0)}`.toUpperCase();
};

export const getOnlineConsumerLegalInitials = (
  firstName: string,
  lastName: string,
) => {
  if (!firstName || !lastName) return null;

  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
};

export const capitalizeAllWords = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const getBaseRoute = () => {
  switch (getAppRole()) {
    case ROLES.OYO_ADMIN: {
      return AppRoutes.Communities;
    }

    case ROLES.PMO: {
      return AppRoutes.MyCommunity;
    }

    case ROLES.CONSUMER:
    case ROLES.PRODUCER: {
      return AppRoutes.Dashboard;
    }

    default: {
      return AppRoutes.SignIn;
    }
  }
};

type NavLink = {
  title: string;
  path: AppRoutes;
  isDisabled?: boolean;
};

export const getNavLinks = (): NavLink[] => {
  switch (getAppRole()) {
    case ROLES.OYO_ADMIN: {
      return COMMUNITIES_NAV_LINKS_ROLE_OYO_ADMIN;
    }

    case ROLES.PMO: {
      return COMMUNITIES_NAV_LINKS_ROLE_PMO;
    }

    case ROLES.CONSUMER: {
      return COMMUNITIES_NAV_LINKS_ROLE_CONSUMER;
    }

    case ROLES.PRODUCER: {
      return COMMUNITIES_NAV_LINKS_ROLE_PRODUCER;
    }

    default: {
      return [];
    }
  }
};

export const getUseMenuByRole = () => {
  switch (getAppRole()) {
    case ROLES.OYO_ADMIN: {
      return USER_MENU_ITEMS_ROLE_OYO_ADMIN;
    }
    case ROLES.PMO: {
      return USER_MENU_ITEMS_ROLE_PMO;
    }
    case ROLES.CONSUMER: {
      return USER_MENU_ITEMS_ROLE_CONSUMER;
    }
    case ROLES.PRODUCER: {
      return USER_MENU_ITEMS_ROLE_PRODUCER;
    }
    default: {
      return [];
    }
  }
};

export const createHeadersWithAuthorization = (
  headers: Headers,
  contentType?: string,
) => {
  if (contentType) {
    headers.set('Content-Type', contentType);
  } else {
    headers.delete('Content-Type');
  }
  headers.set(
    'Authorization',
    `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEYS.AccessToken)}`,
  );
  return headers;
};

export const isImageSmall = (
  imageElement: HTMLImageElement,
  maxWidth: number = 200,
  maxHeight: number = 200,
): boolean => {
  const { width, height } = imageElement.getBoundingClientRect();
  return width < maxWidth && height < maxHeight;
};

export function timeAgo(dateString: string): string {
  if (!dateString) {
    return '';
  }

  const now = new Date();
  const date = new Date(dateString);
  const diff = Math.round((now.getTime() - date.getTime()) / 60000);

  if (diff < 1) {
    return 'just now';
  } else if (diff < 60) {
    return `${diff} mins ago`;
  } else if (diff < 1440) {
    return `${Math.floor(diff / 60)} hrs ago`;
  } else {
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).format(date);
    return formattedDate;
  }
}
