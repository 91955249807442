import { css } from 'styled-components';

export enum BREAKPOINTS_RESOLUTION {
  Medium = 992,
  WSVGA = 1024,
  Large = 1279,
  xLarge = 1440,
  xxLarge = 1920,
}

export const MAX_CONTENT_WIDTH = 1376;

export const ScreenMedium = (styles: TemplateStringsArray | any): any => css`
  @media (max-width: ${BREAKPOINTS_RESOLUTION.Medium}px) {
    ${css(styles)}
  }
`;

export const ScreenLarge = (styles: TemplateStringsArray | any): any => css`
  @media (max-width: ${BREAKPOINTS_RESOLUTION.Large}px) {
    ${css(styles)}
  }
`;

export const ScreenXLarge = (styles: TemplateStringsArray | any): any => css`
  @media (max-width: ${BREAKPOINTS_RESOLUTION.xLarge}px) {
    ${css(styles)}
  }
`;

export const ScreenXXLarge = (styles: TemplateStringsArray | any): any => css`
  @media (max-width: ${BREAKPOINTS_RESOLUTION.xxLarge}px) {
    ${css(styles)}
  }
`;

export const ScreenContentMaxSize = (
  styles: TemplateStringsArray | any,
): any => css`
  @media (max-width: ${MAX_CONTENT_WIDTH}px) {
    ${css(styles)}
  }
`;

export const ScreenWSVGA = (styles: TemplateStringsArray | any): any => css`
  @media (max-width: ${BREAKPOINTS_RESOLUTION.WSVGA}px) {
    ${css(styles)}
  }
`;
