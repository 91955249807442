export enum FONTS {
  HEADER_FONT = '20px',
  SUB_HEADER_FONT = '16px',
  BASE_TEXT_FONT = '14px',
  TEXT_FONT = '12px',
  SUB_TEXT_FONT = '10px',
}

export enum FONTS_WEIGHT {
  TEXT_REGULAR = '400',
  TEXT_BOLD = '600',
}
