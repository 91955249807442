import { createApi } from '@reduxjs/toolkit/query/react';

import { TopicCategory } from 'constants/types';

import { API_URLS, REQUEST_METHOD } from '../constants/global';
import { baseQueryForum } from './baseQuery';

export const topicCategoryManagement = createApi({
  reducerPath: 'topicCategroyApi',
  baseQuery: baseQueryForum,
  endpoints: (builder) => ({
    getAllTopicsCategories: builder.query<TopicCategory[], void>({
      query: () => ({
        url: API_URLS.TOPICS_CATEGORY,
        method: REQUEST_METHOD.GET,
      }),
    }),

    getTopicCategoryById: builder.query<TopicCategory, string>({
      query: (id) => ({
        url: `${API_URLS.TOPICS_CATEGORY}/${id}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),
});

export const { useGetAllTopicsCategoriesQuery, useGetTopicCategoryByIdQuery } =
  topicCategoryManagement;
