import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.ColorSplit};
  padding-left: 16px;
  margin-bottom: 36px;
`;
