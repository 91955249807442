import styled from 'styled-components';

import { Tag } from 'antd';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.BgContainer};
  border-radius: 8px 8px 0 0;
`;

export const PostDate = styled.div`
  color: ${COLORS.colorTextSecondary};
  font-size: ${FONTS.SUB_TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  line-height: 15px;
`;

export const StyledUserDetails = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-left: 16px;
`;

export const CustomTag = styled(Tag)`
  background-color: ${COLORS.Green2};
  color: ${COLORS.Green7};
  font-size: ${FONTS.SUB_TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  line-height: 20px;
  border-radius: 0px 0px 4px 4px;
  border: none;
  padding: 1px 8px;
  width: 100%;
  height: 22px;
`;
