import styled from 'styled-components';

import { Dropdown } from 'antd';

import { COLORS } from 'constants/colors';

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-top: 25px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.BgLayout};
  }
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const Menu = styled.div`
  position: absolute;
  right: 0;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 8px;
  display: none;
`;

export const MenuItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.BgLayout};
  }
`;
