import { styled } from 'styled-components';

import { Avatar, Button } from 'antd';

import {
  BellTwoTone,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
} from '@ant-design/icons';

import { COLORS } from 'constants/colors';

export const StyledNotificationsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

export const BellIcon = styled(BellTwoTone).attrs(() => ({
  twoToneColor: COLORS.Green6,
}))``;

export const dropdownOverlayStyle = {
  zIndex: 100002,
  textTransform: 'capitalize',
};

export const StyledAvatar = styled(Avatar)`
  background: ${COLORS.BgContainer};
  border: 1px solid ${COLORS.Green8};
  cursor: pointer;
  &: hover {
    background: ${COLORS.Green1};
  }
`;

export const StyledNotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 20px 14px;
  border-radius: 8px;
  box-shadow:
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${COLORS.BgContainer};
`;

export const StyledNotificationsHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  padding-bottom: 12px;
  border-bottom: 1px solid ${COLORS.ColorSplit};
`;

export const StyledNotificationsBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  gap: 24px;
  min-height: 340px;
  max-height: 540px;
  overflow-y: scroll;
`;

export const StyledNotificationsEmpty = styled.div`
  margin-top: 50px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

export const StyledNotificationContainer = styled.div<{ isRead: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 20px 8px;
  border-radius: 8px;
  background: ${({ isRead }) => (isRead ? COLORS.Grey2 : COLORS.BgContainer)};
`;

export const StyledNotificationRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledNotificationCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledNotificationType = styled.div``;

export const StyledNotificationTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const StyledNotificationDate = styled.div`
  color: ${COLORS.TextSecondary};
  text-transform: none;
`;

export const StyledNotificationAction = styled(Button)`
  color: ${COLORS.Green5};
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  width: fit-content;
`;

export const SuccessIcon = styled(CheckCircleFilled)`
  color: ${COLORS.Green5};
  font-size: 24px;
`;

export const ErrorIcon = styled(CloseCircleFilled)`
  color: ${COLORS.ErrorBase};
  font-size: 24px;
`;

export const WarningIcon = styled(ExclamationCircleFilled)`
  color: ${COLORS.Orange4};
  font-size: 24px;
`;

export const InfoIcon = styled(InfoCircleFilled)`
  color: ${COLORS.Blue6};
  font-size: 24px;
`;

export const UnreadIcon = styled.div`
  background: ${COLORS.PrimaryBase};
  width: 14px;
  height: 14px;
  border-radius: 7px;
`;
