import React from 'react';

import { Media } from 'constants/types';

import Tags from './components/Tags';
import TopicContent from './components/TopicContent';
import TopicMedia from './components/TopicMedia';
import { BodyContainer } from './styles';

interface TopicBodyProps {
  title: string;
  content: string;
  tags: string[];
  medias: Media[];
}

const TopicBody: React.FC<TopicBodyProps> = ({
  title,
  content,
  medias,
  tags,
}) => {
  return (
    <BodyContainer>
      <TopicContent title={title} content={content} />
      <Tags tags={tags} />
      {medias?.length > 0 && (
        <>
          {medias.map((media) => (
            <TopicMedia
              key={media._id}
              alt={media.originalname}
              mediaType={media.mediaType}
              fileName={media.filename}
            />
          ))}
        </>
      )}
    </BodyContainer>
  );
};

export default TopicBody;
