import styled from 'styled-components';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const DropZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${COLORS.Grey14};
  background-color: ${COLORS.Grey8};
  border-radius: 6px;
  height: 89px;
  width: 472px;
  cursor: pointer;
`;

export const Icon = styled.div`
  padding: 0;
  margin: 0;
  size: 24px;
  color: ${COLORS.Grey15};
`;

export const Text = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  line-height: 20px;
  color: ${COLORS.ActiveUserText};
`;

export const SubText = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  line-height: 20px;
  color: ${COLORS.colorTextDropDown};
`;
