import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const SearchInput = styled.input`
  border: none;
  outline: none;
  background: transparent;
  width: 590px;
  height: 40px;
  border-radius: 25px;
  background-color: ${COLORS.Grey8};
  border: 1px ${COLORS.Grey10};
  color: ${COLORS.TextBase};
  padding-left: 9px;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;
