import { Topic } from 'constants/types';

import { TopicContent } from '../TopicContent';

interface TopicCardProps {
  topics: Topic[];

  refetchPinned: () => void;
  refetchUnpinned: () => void;
}

export const TopicCard: React.FC<TopicCardProps> = ({
  topics,
  refetchPinned,
  refetchUnpinned,
}) => (
  <>
    {topics.map((topic) => (
      <TopicContent
        topic={topic}
        refetchPinned={refetchPinned}
        refetchUnpinned={refetchUnpinned}
      />
    ))}
  </>
);
