import React from 'react';

import { Tab } from './styles';

interface CommunityTabProps {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

const CommunityTab: React.FC<CommunityTabProps> = ({
  name,
  isActive,
  onClick,
}) => (
  <Tab isActive={isActive} onClick={onClick}>
    {name}
  </Tab>
);

export default CommunityTab;
