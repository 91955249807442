import styled from 'styled-components';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
  width: 146px;
  height: 32px;
  margin-left: 19px;
  margin-right: 19px;
  &:hover {
    background-color: ${COLORS.Grey8};
  }
`;

export const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 12px;
`;

export const ButtonText = styled.div`
  font-size: ${FONTS.BASE_TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  color: ${COLORS.Grey6};
  line-height: 20px;
`;
