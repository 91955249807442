import React from 'react';

import { MEDIA_TYPE } from 'constants/types';

import { Container, Image, Video } from './styles';

interface TopicMediaProps {
  fileName: string;
  alt: string;
  mediaType: MEDIA_TYPE;
}

const TopicMedia: React.FC<TopicMediaProps> = ({
  fileName,
  alt,
  mediaType,
}) => {
  const mediaUrl = process.env.REACT_APP_BACKEND_FORUM_URL;

  return (
    <Container>
      {mediaType === MEDIA_TYPE.IMAGE ? (
        <Image src={`${mediaUrl}/medias/images/${fileName}`} alt={alt} />
      ) : mediaType === MEDIA_TYPE.VIDEO ? (
        <Video controls muted>
          <source
            src={`${mediaUrl}/medias/videos/${fileName}`}
            type="video/mp4"
          />
        </Video>
      ) : null}
    </Container>
  );
};

export default TopicMedia;
