import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const DashboardContainer = styled.div`
  margin-top: 64px;
  display: flex;
  justify-content: center;
  background-color: ${COLORS.BgLayout};
  min-height: 100vh;
  width: 100%;
`;

export const Content = styled.div`
  flex-grow: 1;
  flex-direction: column;
  margin-top: 32px;
  justify-content: center;
  max-width: 674px;
`;
