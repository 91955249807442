import styled from 'styled-components';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const Container = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  width: 183px;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${COLORS.Grey8};
  }
`;

export const MenuIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${COLORS.Grey8};
  border-radius: 7px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuText = styled.div`
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  line-height: 12px;
  cursor: pointer;
  padding-left: 8px;
  color: ${COLORS.Grey6};
`;
