import styled from 'styled-components';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const Title = styled.p`
  padding-top: 9px;
  font-size: ${FONTS.SUB_HEADER_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  line-height: 24px;
  color: ${COLORS.colorTextHeading};
  margin: 0;
`;

export const StyledText = styled.span`
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  line-height: 20px;
  color: ${COLORS.colorTextHeading};
  margin: 0;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const Content = styled.span`
  font-size: ${FONTS.TEXT_FONT};
  font-weight: ${FONTS_WEIGHT.TEXT_REGULAR};
  line-height: 20px;
  color: ${COLORS.colorTextHeading};
  margin: 0;
`;
