import { createApi } from '@reduxjs/toolkit/query/react';

import { Topic } from 'constants/types';

import { API_URLS, REQUEST_METHOD } from '../constants/global';
import { baseQueryForumFormData } from './baseQuery';

export const topicManagement = createApi({
  reducerPath: 'topicApi',
  baseQuery: baseQueryForumFormData,
  endpoints: (builder) => ({
    getAllTopics: builder.query<Topic[], void>({
      query: () => ({
        url: API_URLS.TOPICS,
        method: REQUEST_METHOD.GET,
      }),
    }),

    getRecentPinnedTopics: builder.query<Topic, void>({
      query: () => ({
        url: `${API_URLS.TOPICS}/${API_URLS.RECENT_PINNED}`,
        method: REQUEST_METHOD.GET,
      }),
    }),

    addTopic: builder.mutation<Topic, FormData>({
      query: (newTopic) => ({
        url: API_URLS.TOPICS,
        method: REQUEST_METHOD.POST,
        body: newTopic,
      }),
    }),

    updateTopic: builder.mutation<
      Topic,
      { id: string; updatedTopic: FormData }
    >({
      query: ({ id, updatedTopic }) => ({
        url: `${API_URLS.TOPICS}/${id}`,
        method: REQUEST_METHOD.PUT,
        body: updatedTopic,
      }),
    }),

    deleteTopic: builder.mutation<void, string>({
      query: (id) => ({
        url: `${API_URLS.TOPICS}/${id}`,
        method: REQUEST_METHOD.DELETE,
      }),
    }),
    getTopicsByCategory: builder.query<Topic[], string>({
      query: (categoryId) => ({
        url: `${API_URLS.TOPICS}/${categoryId}/${API_URLS.CATEGORY}`,
        method: REQUEST_METHOD.GET,
      }),
    }),

    getUnpinnedTopicsByCommunity: builder.query<Topic[], string>({
      query: (communityId) => ({
        url: `${API_URLS.TOPICS}/${communityId}/${API_URLS.UNPINNED_COMMUNITY}`,
        method: REQUEST_METHOD.GET,
      }),
    }),

    getPinnedTopicsByCommunity: builder.query<Topic[], string>({
      query: (communityId) => ({
        url: `${API_URLS.TOPICS}/${communityId}/${API_URLS.PINNED_COMMUNITY}`,
        method: REQUEST_METHOD.GET,
      }),
    }),

    getTopicsByCategoryAndCommunity: builder.query<
      Topic[],
      { categoryId: string; communityId: string }
    >({
      query: ({ categoryId, communityId }) => ({
        url: `${API_URLS.TOPICS}/${categoryId}/${API_URLS.CATEGORY}/${communityId}/${API_URLS.GET_COMMUNITY}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),
});

export const {
  useGetAllTopicsQuery,
  useGetRecentPinnedTopicsQuery,
  useAddTopicMutation,
  useUpdateTopicMutation,
  useDeleteTopicMutation,
  useGetTopicsByCategoryQuery,
  useGetPinnedTopicsByCommunityQuery,
  useGetUnpinnedTopicsByCommunityQuery,
  useGetTopicsByCategoryAndCommunityQuery,
} = topicManagement;
