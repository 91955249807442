import React from 'react';

import { MessageOutlined } from '@ant-design/icons';

import { t } from 'i18next';

import { ButtonContainer, ButtonIcon, ButtonText } from '../LikeButton/styles';

const CommentButton: React.FC = () => (
  <ButtonContainer>
    <ButtonIcon>
      <MessageOutlined />
    </ButtonIcon>
    <ButtonText>{t('comment')}</ButtonText>
  </ButtonContainer>
);

export default CommentButton;
