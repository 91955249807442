import React from 'react';

import { Topic } from 'constants/types';

import { PostContainer } from '../TopicCard/styles';
import TopicBody from './components/TopicBody';
import TopicFooter from './components/TopicFooter';
import TopicHeader from './components/TopicHeader';

interface TopicContentProps {
  topic: Topic;
  refetchPinned: () => void;
  refetchUnpinned: () => void;
}

export const TopicContent: React.FC<TopicContentProps> = ({
  topic,
  refetchPinned,
  refetchUnpinned,
}) => (
  <PostContainer key={topic._id}>
    <TopicHeader
      topic={topic}
      refetchPinned={refetchPinned}
      refetchUnpinned={refetchUnpinned}
    />
    <TopicBody
      title={topic.title}
      medias={topic.media || []}
      content={topic.description}
      tags={['meetings', 'discussions']}
    />
    <TopicFooter />
  </PostContainer>
);
