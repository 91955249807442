import {
  getConsumerLegalInitials,
  getOnlineConsumerLegalInitials,
} from 'utils';

import { useTranslation } from 'react-i18next';

import { COLORS } from 'constants/colors';
import { USER_ROLES } from 'constants/types';

import {
  Container,
  SidebarContainer,
  SidebarHeader,
  StatusIcon,
  ThunderboltIcon,
  UserItem,
  UserName,
  UserRole,
} from './styles';

const users = [
  {
    firstName: 'Marva',
    lastName: 'Haddad',
    role: 'OYO Communities',
    status: COLORS.Orange4,
    userRole: USER_ROLES.ADMIN,
  },
  {
    firstName: 'Alessandro',
    lastName: 'Pitti',
    role: 'PMO',
    status: COLORS.Blue5,
    userRole: USER_ROLES.PMO_ADMIN,
  },
  {
    firstName: 'Cesare',
    lastName: 'Fieci',
    role: 'Producer',
    status: COLORS.Yellow2,
    userRole: USER_ROLES.PRODUCER,
  },
  {
    firstName: 'Laura',
    lastName: 'Botinelli',
    role: 'Consumer',
    status: COLORS.Green5,
    userRole: USER_ROLES.CONSUMER,
  },
  {
    firstName: 'Denis',
    lastName: 'Darra',
    role: 'Consumer',
    status: COLORS.Green5,
    userRole: USER_ROLES.CONSUMER,
  },
  {
    firstName: 'Sebastian',
    lastName: 'Ponti',
    role: 'Consumer',
    status: COLORS.Green5,
    userRole: USER_ROLES.CONSUMER,
  },
  {
    firstName: 'Tiago',
    lastName: 'Groupama',
    role: 'Producer',
    status: COLORS.Yellow2,
    userRole: USER_ROLES.PRODUCER,
  },
  {
    firstName: 'Fatma',
    lastName: 'Blagui',
    role: 'Consumer',
    status: COLORS.Green5,
    userRole: USER_ROLES.CONSUMER,
  },
];

const getIconContent = (
  userRole: string,
  firstName: string,
  lastName: string,
) => {
  switch (userRole) {
    case USER_ROLES.ADMIN:
      return 'OYO';
    case USER_ROLES.PMO_ADMIN:
      return 'PMO';
    case USER_ROLES.PRODUCER:
      return <ThunderboltIcon />;
    case USER_ROLES.CONSUMER:
      return getOnlineConsumerLegalInitials(firstName, lastName);
    default:
      return '';
  }
};

const getIconColor = (userRole: string) => {
  switch (userRole) {
    case USER_ROLES.ADMIN:
      return COLORS.Orange5;
    case USER_ROLES.PMO_ADMIN:
      return COLORS.geekBlue5;
    case USER_ROLES.PRODUCER:
      return COLORS.Yellow5;
    case USER_ROLES.CONSUMER:
      return COLORS.Green5;
    default:
      return '';
  }
};

const getIconBorderColor = (userRole: string) => {
  switch (userRole) {
    case USER_ROLES.ADMIN:
      return COLORS.Orange8;
    case USER_ROLES.PMO_ADMIN:
      return COLORS.Blue4;
    case USER_ROLES.PRODUCER:
      return COLORS.Yellow6;
    case USER_ROLES.CONSUMER:
      return COLORS.Green6;
    default:
      return '';
  }
};

const RightSidebar = () => {
  const { t } = useTranslation();

  return (
    <SidebarContainer>
      <Container>
        <SidebarHeader>{t('community_members')}</SidebarHeader>
        {users.map((user, index) => (
          <UserItem key={index}>
            <StatusIcon
              color={getIconColor(user.userRole)}
              borderColor={getIconBorderColor(user.userRole)}
            >
              {getIconContent(user.userRole, user.firstName, user.lastName)}
            </StatusIcon>
            <div>
              <UserName>
                {user.firstName} {user.lastName}
              </UserName>
              {user.role &&
                user.role !== USER_ROLES.CONSUMER &&
                user.role !== USER_ROLES.PRODUCER && (
                  <UserRole>{user.role}</UserRole>
                )}
            </div>
          </UserItem>
        ))}
      </Container>
    </SidebarContainer>
  );
};

export default RightSidebar;
