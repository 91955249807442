import styled from 'styled-components';

import { Button, Input } from 'antd';

import { COLORS } from 'constants/colors';

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
`;
export const StyledForgotPassword = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
export const StyledBackToLogin = styled(Button)<{ color?: boolean }>`
  margin: auto;
  width: 140px;
  font-size: 14px;
  font-weight: 600;
  padding: 0;

  &:hover {
    color: ${COLORS.TextBase} !important;
  }

  &:disabled {
    color: ${COLORS.Grey3};
  }

  &:hover:disabled {
    color: ${COLORS.Grey3} !important;
  }
`;

export const StyledInput = styled(Input)`
  height: 40px;
`;
export const StyledPasswordInput = styled(Input.Password)`
  height: 40px;
`;
