import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const PostContainer = styled.div`
  background: ${COLORS.BgContainer};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
`;
