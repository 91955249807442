import { useTranslation } from 'react-i18next';

import AppFeedbacksIcon from '../LeftSideBar/icons/mycommunity.svg';
import {
  ArrowDownOutlined,
  DividerContainer,
  DividerContent,
  DividerLine,
  DividerText,
} from './styles';

export const Divider = () => {
  const { t } = useTranslation();

  return (
    <DividerContainer>
      <DividerLine />
      <DividerContent>
        <img src={AppFeedbacksIcon} alt={t('app_feedbacks')} />
        <DividerText>{t('all_posts')}</DividerText>
        <ArrowDownOutlined />
      </DividerContent>
    </DividerContainer>
  );
};

export default Divider;
