import React from 'react';

import { Topic } from 'constants/types';

import { TopicContent } from '../TopicContent';
import { CustomCarousel } from './styles';

interface TopicCarouselProps {
  topics: Topic[];
  refetchPinned: () => void;
  refetchUnpinned: () => void;
}

const TopicCarousel: React.FC<TopicCarouselProps> = ({
  topics,
  refetchPinned,
  refetchUnpinned,
}) => {
  const carouselSettings = {
    dots: true,
    infinite: topics.length > 1,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
  };

  const displayedTopics = topics.slice(0, 4);

  return (
    <CustomCarousel {...carouselSettings}>
      {displayedTopics.map((topic) => (
        <TopicContent
          key={topic._id}
          topic={topic}
          refetchPinned={refetchPinned}
          refetchUnpinned={refetchUnpinned}
        />
      ))}
    </CustomCarousel>
  );
};

export default TopicCarousel;
