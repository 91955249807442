import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 19px;
  margin-right: 19px;
  align-items: center;
  justify-content: center;
`;
export const HorizontalBar = styled.div`
  width: 100%;
  border: 1px solid ${COLORS.Grey12};
  margin-top: 8px;
  margin-bottom: 8px;
`;
