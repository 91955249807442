import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from 'components/shared/Header';
import { StyledPageWrapper } from 'components/shared/MainOutlet/styles';

const MainOutlet: React.FC = () => {
  return (
    <>
      <Header />
      <StyledPageWrapper>
        <Outlet />
      </StyledPageWrapper>
    </>
  );
};

export default MainOutlet;
