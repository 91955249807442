import * as Yup from 'yup';

import { t } from 'i18next';

export const createTopicValidationSchema = () => {
  return Yup.object().shape({
    description: Yup.string()
      .min(10, t('validation.description_min', { min: 10 }))
      .max(500, t('validation.description_max', { max: 500 }))
      .required(t('validation.description_required')),
  });
};

export const getSignInSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    username: Yup.string()
      .required(t('validation.email_required'))
      .matches(
        /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t('validation.email_format'),
      ),
    password: Yup.string().required(t('validation.password_required')),
  });

export const getCreatePasswordSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t('validation.password_required'))
      .matches(/\d+/, t('validation.password_at_least_one_number'))
      .matches(/[a-z]+/, t('validation.password_at_least_one_lowercase'))
      .matches(/[A-Z]+/, t('validation.password_at_least_one_uppercase'))
      .matches(/[!@#$%^&*()-+]+/, t('validation.password_at_least_one_special'))
      .min(8, t('validation.password_length')),
  });
