import React from 'react';

import CommentButton from '../../../shared/CommentButton';
import LikeButton from '../../../shared/LikeButton';
import { Container } from './styles';

const InteractionButtons: React.FC = () => (
  <Container>
    <LikeButton />
    <CommentButton />
  </Container>
);

export default InteractionButtons;
