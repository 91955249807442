export enum COLORS {
  // BG Colors
  BgLayout = '#F5F5F5',
  BgContainer = '#FFFFFF',
  BgContainerDisabled = '#D9D9D9',

  BgElevated = '#FFFFFF',
  Border = '#F0F0F0',
  EmptyState = '#E2E2E2',

  colorText = '#00000005',
  colorWrapper = '#00000008',
  colorTextSecondary = '#000000A6',
  colorTextDropDown = '#667085',
  colorTextHeading = '#000000E0',
  colorBgTextHover = '#E6E6E6',
  colorTextPlaceholder = '#00000040',

  // Green Colors
  Green1 = '#DCFCE7',
  Green2 = '#BBF7D0',
  Green3 = '#86EFAC',
  Green4 = '#4ADE80',
  Green5 = '#22C55E',
  Green6 = '#16A34A',
  Green7 = '#15803D',
  Green8 = '#166534',
  Green9 = '#14532D',
  Green10 = '#092916',

  //Grey Colors
  Grey1 = '#BFBFBF',
  Grey2 = '#EDEDF0',
  Grey3 = '#B2B2B4',
  Grey4 = '#565656',
  Grey5 = '#1E1E1E',
  Grey6 = '#475467',
  Grey7 = '#D8D8D8',
  Grey8 = '#F2F4F7',
  Grey9 = '#595959',
  Grey10 = '#B9B9B9',
  Grey11 = '#00000073',
  Grey12 = '#0000000F',
  Grey13 = '#E4E7EC',
  Grey14 = '#D0D5DD',
  Grey15 = '#98A2B3',

  TextBase = '#000000',
  ActiveUserText = '#0C111D',
  TextSecondary = '#4D5851',
  TextDescription = '#00000073',
  TextTertiary = '#8c8c8c',
  ColorFillTertiary = '#00000008',
  ColorSplit = '#AEAEAE',

  // Orange Colors
  Orange1 = '#FFEDD5',
  Orange2 = '#FED7AA',
  Orange3 = '#FDBA74',
  Orange4 = '#FB923C',
  Orange5 = '#F97316',
  Orange6 = '#EA580C',
  Orange7 = '#C2400C',
  Orange8 = '#C2410C',

  // Yellow Colors
  Yellow1 = '#FEF9C3',
  Yellow2 = '#FEF08A',
  Yellow4 = '#FACC15',
  Yellow5 = '#EAB308',
  Yellow6 = '#CA8A04',
  Yellow7 = '#A16207',
  Gold5 = '#F59E0B',
  Gold6 = '#D97706',
  Gold7 = '#B45309',

  //Cyan
  Cyan7 = '#0E7490',

  //Blue
  Blue3 = '#007bff',
  Blue4 = '#1D4ED8',
  Blue5 = '#0EA5E9',
  Blue6 = '#0284C7',
  geekBlue5 = '#3B82F6',
  geekBlue6 = '#2563EB',

  ErrorBase = '#EF4444',
  ErrorBorder = '#F87171',

  SuccessBase = '#52c41a',

  PrimaryBase = '#1677FF',
  InputBorder = '#4096ff',

  WarningBase = '#FAAD14',
}
