import { createApi } from '@reduxjs/toolkit/query/react';

import { API_URLS, REQUEST_METHOD } from 'constants/global';
import { Community } from 'constants/types';

import { baseQueryBackend } from './baseQuery';

export const communityManagement = createApi({
  reducerPath: 'communityApi',
  baseQuery: baseQueryBackend,
  endpoints: (builder) => ({
    getCommunity: builder.query<Community, void>({
      query: () => ({
        url: API_URLS.GET_COMMUNITY,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunityById: builder.query<Community, { communityId: string }>({
      query: ({ communityId }) => ({
        url: `${API_URLS.GET_COMMUNITY}/${communityId}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunityByUserId: builder.query<Community, { userId: string }>({
      query: ({ userId }) => ({
        url: `${API_URLS.GET_COMMUNITY}/${userId}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
    getCommunities: builder.query<Community[], void>({
      query: () => ({
        url: API_URLS.GET_COMMUNITIES,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),

  tagTypes: ['communities'],
});

export const {
  useGetCommunityQuery,
  useGetCommunityByUserIdQuery,
  useGetCommunityByIdQuery,
  useGetCommunitiesQuery,
} = communityManagement;
