import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export const StyledWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  background-color: ${COLORS.BgElevated};
  box-shadow:
    0px 1px 6px -1px ${COLORS.colorText},
    0px 1px 6px -1px ${COLORS.colorText},
    0px 1px 2px 0px ${COLORS.colorWrapper};
`;

export const LogoContainer = styled.div`
  cursor: pointer;
  padding: 12px 32px;
`;
