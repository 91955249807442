import React from 'react';

import { BarType } from 'constants/global';

import InteractionButtons from './InteractionButtons';
import InteractionCounter from './InteractionCounter';
import { Container, HorizontalBar } from './styles';

const TopicFooter: React.FC = () => (
  <>
    <InteractionCounter likes={36545} comments={56} />
    <Container>
      <HorizontalBar />
      <InteractionButtons />
      <HorizontalBar />
    </Container>
  </>
);

export default TopicFooter;
