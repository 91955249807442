import { createApi } from '@reduxjs/toolkit/query/react';

import { API_URLS, REQUEST_METHOD } from 'constants/global';

import { baseQueryBackend } from './baseQuery';
import { IPayment, IPaymentMethod } from './types';

export const paymentApi = createApi({
  reducerPath: 'payment',
  baseQuery: baseQueryBackend,
  endpoints: (builder) => ({
    createPayment: builder.mutation<IPayment, any>({
      query: () => ({
        url: `${API_URLS.PAYMENT_METHOD}`,
        method: REQUEST_METHOD.POST,
      }),
    }),
    deletePayment: builder.mutation<any, string | undefined>({
      query: (id) => ({
        url: `${API_URLS.PAYMENT_METHOD}/${id}`,
        method: REQUEST_METHOD.DELETE,
      }),
    }),
    updatePayment: builder.mutation<any, string | undefined>({
      query: (id) => ({
        url: `${API_URLS.PAYMENT_METHOD}/${id}`,
        method: REQUEST_METHOD.PUT,
      }),
    }),
    getPayments: builder.query<
      { paymentMethods: IPaymentMethod[] },
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `${API_URLS.PAYMENT_METHOD}${id ? `/${id}` : ''}`,
        method: REQUEST_METHOD.GET,
      }),
    }),
  }),
  tagTypes: ['payment'],
});

export const {
  useCreatePaymentMutation,
  useGetPaymentsQuery,
  useDeletePaymentMutation,
  useUpdatePaymentMutation,
} = paymentApi;
