import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import ForumDashboard from 'components/ForumDashboard';
import SignIn from 'components/auth/SignIn';
import UnprotectedRoute from 'components/hoc/UnprotectedRoute';
import MainOutlet from 'components/shared/MainOutlet';
import PrivateRoute from 'components/shared/PrivateRoute';
import ScrollToTop from 'components/shared/ScrollToTop';
import AppRoutes from 'constants/routes';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route index element={<Navigate replace to={AppRoutes.Forum} />} />

        <Route
          path={AppRoutes.SignIn}
          element={
            <UnprotectedRoute>
              <SignIn />
            </UnprotectedRoute>
          }
        />
        <Route
          path={AppRoutes.Root}
          element={
            <PrivateRoute>
              <MainOutlet />
            </PrivateRoute>
          }
        >
          <Route path={AppRoutes.Forum} element={<ForumDashboard />} />
        </Route>
        <Route path="*" element={<Navigate replace to={AppRoutes.Root} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
