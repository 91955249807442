import styled from 'styled-components';

import { ThunderboltTwoTone } from '@ant-design/icons';

import { COLORS } from 'constants/colors';
import { FONTS, FONTS_WEIGHT } from 'constants/fonts';

export const UserIcons = styled.div<{ borderColor: string }>`
  display: flex;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  color: ${COLORS.BgContainer};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 21px;
  font-weight: ${FONTS_WEIGHT.TEXT_BOLD};
  font-size: ${FONTS.TEXT_FONT};
  line-height: 20px;
  margin-right: 12px;
`;

export const ThunderboltIcon = styled(ThunderboltTwoTone).attrs(() => ({
  twoToneColor: COLORS.Yellow2,
}))`
  font-size: ${FONTS.SUB_HEADER_FONT};
`;
